.Layout {
    background-color: #fff;
    width: 100%;
    /*height: 100vh;*/
    position: relative;
}

.wrap {
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 150px;
    padding-left: 300px;
    /*box-sizing: border-box;*/
}

.footerContainer {
    background: rgba(55, 142, 241, 0.06);
    padding: 45px 30px;
}

.footerContainer .footerContentBox {
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerContainer .footerContentBox .tabletFooter {
    display: none;
}

.footerContainer .footerContentBox .defaultFooter {
    /*display: block;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footerContainer .footerContentBox .whitePaper .labelLogo{
    width: 89px;
    height: 53px;
    margin-bottom: 20px;
}


/*.footerContainer .footerContentBox .whitePaper .whitePaperDown {*/
/*    background: rgba(36, 124, 238, 0.1);*/
/*    border-radius: 23px;*/
/*    border: none;*/
/*    padding: 10px 24px;*/
/*    cursor: pointer;*/

/*}*/

.footerContainer .footerContentBox .whitePaper .whitePaperDown span{
    display: inline-block;
    vertical-align: middle;
    margin-right:8px;
    color: #1A8DF7;
    font-weight: bold;
    font-size: 15px;
}
/*.footerContainer .footerContentBox .whitePaper .whitePaperDown span {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    margin-right: 8px;*/
/*    color: #3755F1;*/
/*    font-weight: bold;*/
/*    font-size: 15px;*/
/*}*/

/*.footerContainer .footerContentBox .whitePaper .whitePaperDown img {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    width: 17.5px;*/
/*    height: 18px;*/
/*}*/

/*.footerContainer .footerContentBox .whitePaper .whitePaperDown:hover img {*/
/*    position: relative;*/
/*    animation: slideArrow 1.1s linear infinite;*/
/*}*/


@keyframes slideArrow {
    0% {
        left: 2px;
    }
    50% {
        left: 10px;
    }
    100% {
        left: 2px;
    }
}

.footerContainer .footerContentBox .footerTitle {
    font-weight: bold;
    font-size: 15px;
    color: #333;
    margin-bottom: 20px;
    display: block;
}

.footerContainer .footerContentBox .content {
    font-size: 15px;
    color: #333;
    display: block;
    margin-bottom: 10px;
}

.footerContainer .footerContentBox .content:last-child {
    margin-bottom: 0;
}

.footerContainer .footerContentBox .snsLink .sns {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    background-color: #fff;
}

.footerContainer .footerContentBox .snsLink .sns:last-child {
    margin-right: 0;
}

.footerContainer .footerContentBox .snsLink .sns.twitter {
    background-image: url("assets/icons/twitter_gray.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.twitter:hover {
    background-image: url("assets/icons/twitter_blue_icon.svg");
}


.footerContainer .footerContentBox .snsLink .sns.telegram {
    background-image: url("assets/icons/telegram_gray.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.telegram:hover {
    background-image: url("assets/icons/telegram_blue_icon.svg");

}

.footerContainer .footerContentBox .snsLink .sns.discord {
    background-image: url("assets/icons/discord_gray.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.discord:hover {
    background-image: url("assets/icons/discord_blue_icon.svg");
}

.footerContainer .footerContentBox .snsLink .sns.kakao {
    background-image: url("assets/icons/kakao_gray.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.kakao:hover {
    background-image: url("assets/icons/kakaotalk_blue_icon.png");
}


.footerContainer .footerContentBox .snsLink .sns.medium {
    background-image: url("assets/icons/medium_gray.svg");
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.medium:hover{
    background-image: url("assets/icons/medium_blue_icon.svg");

}

/* sideMenu */
.sideMenu {
    width:300px;
    position: fixed;
    top: 90px;
    left: 40px;
    z-index: 100;
    padding: 40px 30px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
}

.sideMenu .topContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}


.sideMenu .labelLogo{
    width: 95px;
    height: 53px;

    backface-visibility: hidden;
}
.langContainer{
    border: 1px solid #C9C9C9;
    border-radius: 14px;
    width: 84px;
    height: 28px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.langContainer.langMobile{
    width: 72px;
    height: 24px;
    /*margin-right: 20px;*/
}
.langContainer.langMobile a{
    font-size: 12px;
}
.langContainer a{
    font-size: 14px;
    font-weight: 500;
    color: #707070;
    text-decoration: none;
}
.langContainer a.selected{
    color:#1A8DF7;
}

.sideMenu span {
    display: block;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}

.sideMenu .sideActive span {
    color: #1A8DF7;
}

.mobileSideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #fff;
    z-index: 3;
    display: none;
}

.mobileSideMenu.menuAnimateLeft {
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: animateleft;
}

.mobileSideMenu .menuTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 2px solid #F1F1F1;
}

.mobileSideMenu .menuTop .labelLogo {
    height: 40px;
}
.mobileSideMenu .menuTop .labelLogo img {
    width: 80px;
    height: auto;
    backface-visibility: hidden;
}

.mobileSideMenu .menuTop .menuClose {
    width: 30px;
    height: 30px;
}



.mobileSideMenu .menuList{

    padding: 30px 24px;
}

.mobileSideMenu .menuList li {
    list-style: none;
    margin-bottom: 30px;
}

.mobileSideMenu .menuList li:last-child {
    margin-bottom: 40px;
}

.mobileSideMenu .menuList .navName {
    /*display: block;*/
    color: #707070;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}



.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: none;
}

/* navigation */
.navigation {
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1;
    border: 1px solid #F1F1F1;
    display: none;
}

.navigationContainer {
    /*max-width: 360px;*/
    margin: 0 auto;
    padding: 10px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigationContainer .menuIcon {
    width: 75px;
    /*width: 30px;*/
    /*height: 30px;*/
    /*float: left;*/
    /*margin-left: 20px;*/
}

.navigationContainer .logoSmall {
    width: 75px;
    height: 28px;
    margin: 0 auto;
    box-sizing: border-box;
    /*backface-visibility: hidden;*/
}

/*.partnersContainer {*/
/*    max-width: 1630px;*/
/*}*/


@media screen and (max-width: 1700px) {
    .wrap {
        box-sizing: border-box;
    }
    .sideMenu {
        padding: 30px 20px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        backface-visibility: hidden;
    }
}
@media screen and (max-width: 1550px) {

}


@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .wrap {
        max-width: 1200px;
        padding-left: 0;
    }

    .navigationContainer {
        /*max-width: 1000px;*/
    }
    .navigationContainer .logoSmall img {
        backface-visibility: hidden;
    }

    .sideMenu {
        display: none;
    }

    .navigation {
        display: block;
    }

    .footerContainer .footerContentBox .footerTitle span {
        font-size: 14px;
        letter-spacing: -1px;
    }

}
/*@media screen and (max-width: 1250px) {*/
/*    .wrap {*/
/*        width: 1000px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .wrap {
        max-width: 950px;
        overflow: hidden;
        margin-bottom: 100px;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .sideMenu {
        display: none;
    }

    .footerContainer .footerContentBox {
        padding: 0 30px;
        box-sizing: border-box;
    }

    .footerContainer .footerContentBox .tabletFooter {
        display: block;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: space-between;*/
    }

    .footerContainer .footerContentBox .tabletFooter .footerLeft {
        display: inline-block;
        vertical-align: top;
        margin-right: 100px;
    }

    .footerContainer .footerContentBox .tabletFooter .footerRight {
        width: calc(100% - 286px);
        display: inline-block;
        vertical-align: top;
    }

    .footerContainer .footerContentBox .tabletFooter .footerRight .snsLink {
        margin-bottom: 50px;
    }
    .footerContainer .footerContentBox .tabletFooter .footerRight .footerTextContainer{
        display: flex;
        flex-direction: row;
        /*justify-content: space-between;*/
    }
    .footerContainer .footerContentBox .tabletFooter .footerRight .footerTextContainer .privacyPolicy{
        margin-right: 50px;
    }

    .footerContainer .footerContentBox .tabletFooter .footerTitle {
        margin-bottom: 10px;
    }

    .footerContainer .footerContentBox .defaultFooter {
        display: none;
    }
}
@media screen and (max-width: 950px) {
    .wrap {
        max-width: 900px;
    }
}
@media screen and (max-width: 900px) {
    .wrap {
        max-width: 850px;
    }
}
@media screen and (max-width: 850px) {
    .wrap {
        max-width: 800px;
    }
}
@media screen and (max-width: 800px) {
    .wrap {
        max-width: 769px;
    }
}
@media screen and (max-width: 768px) {
    .wrap {
        max-width: 720px;
    }
}
@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .wrap {
        /*max-width: 360px;*/
        overflow: hidden;
        margin-bottom: 50px;
        padding: 0;
        /*padding: 0 30px;*/
        box-sizing: border-box;
    }

    .sideMenu {
        display: none;
    }

    .footerContainer {
        background: rgba(55, 142, 241, 0.06);
        overflow: hidden;
        /*padding: 40px 30px;*/
        padding: 40px;
        box-sizing: border-box;
    }
    .footerContainer .footerContentBox {
        max-width: 768px;
    }


    .footerContainer .footerContentBox {
        display: block;
        text-align: center;
        /*max-width: 360px;*/
        padding: 0;
    }

    .footerContainer .footerContentBox .tabletFooter {
        display: none;
    }

    .footerContainer .footerContentBox .defaultFooter {
        display: block;
    }

    .footerContainer .footerContentBox .whitePaper {
        display: flex;
        flex-direction: row;
        align-items: center;
        /*justify-content: flex-end;*/
        margin-bottom: 30px;
        justify-content: space-between;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown{
        padding: 10px 0;
        width: 162px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span{
        font-size: 14px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 80px;
        height: 30px;
        /*margin: 0 auto;*/
        backface-visibility: hidden;
    }

    /*.footerContainer .footerContentBox .whitePaper .whitePaperDown {*/
    /*    padding: 10px 0;*/
    /*    width: 218px;*/
    /*}*/

    /*.footerContainer .footerContentBox .whitePaper .whitePaperDown span {*/
    /*    font-size: 14px;*/
    /*}*/


    .footerContainer .footerContentBox .whitePaper .labelLogo {
        height: 30px;
        /*margin: 0 auto;*/
        margin-bottom: 10px;
        padding-right: 50px;
    }

    .footerContainer .footerContentBox .footerTitle {
        margin-bottom: 10px;
    }

    .footerContainer .footerContentBox .privacyPolicy, .footerContainer .footerContentBox .contactUs {
        margin-bottom: 30px;
        text-align: left;
    }

    .footerContainer .footerContentBox .snsLink {
        text-align: left
    }

    .footerContainer .footerContentBox .snsLink .sns {
        width: 30px;
        height: 30px;
        margin-right: 16px;
    }

    .navigation {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 15px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 120px;
        height: 50px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 23px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 21px;
    }
}
@media screen and (max-width: 720px) {
    .wrap {
        max-width: 670px;
    }
}
@media screen and (max-width: 720px) {
    .wrap {
        max-width: 620px;
    }
}
@media screen and (max-width: 620px) {
    .wrap {
        max-width: 570px;
    }
}

@media screen and (max-width: 560px) {
    .footerContainer .footerContentBox .footerTitle {
        font-size: 18px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 17px;
    }
}

@media screen and (max-width: 425px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 14px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 35px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 14px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown {
        padding: 10px 0;
        width: 160px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 13px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 25px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 13.5px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 13.5px;
    }
}

@media screen and (max-width: 320px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown {
        padding: 10px 0;
        width: 190px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 12px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 15px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 12px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 12px;
    }
}
