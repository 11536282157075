.AdminMainContainer {
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 20px;
}

.AdminTableContainer {
    width: 100%;
}
 .AdminTableContainer table {
    width: 100%;
}

.AdminTableContainer th, .AdminTableContainer td {
    padding: 15px 10px;
    box-sizing: border-box;
}

.AdminTableContainer tr .checkContact {
    color: #3755F1;
}

.AdminTableContainer tr .noneContact {
    color: red;
}

.AdminTableContainer .id {
    width: 80px
}

.AdminTableContainer .checkIcon svg path {
    color: #008000;
}

.Scroll-wrap {
    width: 100%;
    overflow: auto;
    max-height: 280px;
    padding-bottom: 20px;
    background: #FBF3E4;
    box-shadow: rgba(150, 100, 125, 0.2) 3px 3px 10px inset;
}

.Scroll-wrap .Contact-content {
    font-weight: bold;
    font-size: large;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 35px;
    padding-top: 28px;
    word-break: break-all;

}

.Button-wrap {
    width: 100%;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Table-Header-Sort {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.Table-Header-Sort .Table-Header-Icon {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: left;
    margin-top: 2px;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: left;
}

.triangle-down-gray {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #D5D5D5;
    font-size: 0;
    line-height: 0;
    float: left;
    margin-top: 2px;
}

.triangle-up-gray {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #D5D5D5;
    font-size: 0;
    line-height: 0;
    float: left;
}

