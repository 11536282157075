.modalOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    text-align: center;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1022;

}
.modalOverlay > div {
  //position: relative;
  //margin-top: 200px;
      display: inline-block;
      width: 600px;
      min-height: 200px;
      background: white;
      --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
      box-shadow: 0 0 0 1px var(--saf-0), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
      background-color: rgba(var(--sk_foreground_min_solid, 248, 248, 248), 1);
      border-radius: 6px;
      user-select: none;
      max-width: 440px;
      padding: 30px;
      box-sizing: border-box;
      z-index: 1025;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }
