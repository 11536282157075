
.contactContainer .title {
    text-align: start;
}


.contactContainer {
    padding-bottom: 100px;
}

.contactContainer .formContainer {
    padding: 50px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
    text-align: right;
}

.contactContainer .formContainer .inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    text-align: left;
}

.contactContainer .formContainer .inputContainer .error {
    color: red;
}

.contactContainer .formContainer .left {
    width: 570px
}

.contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.contactContainer .formContainer .left input {
    width: 100%;
    height: 62px;
    /*padding: 16px;*/
    box-sizing: border-box;
    padding-left: 64px;
    border: 2px solid #F1F1F1;
    border-radius: 13px;
    color: #707070;
    font-size: 16px;
}

.contactContainer .formContainer .left input:focus,.contactContainer .formContainer .right textarea:focus {
    outline: 1.3px solid #1A8DF7;

    outline-width: 0;
    border: 1.3px solid #1A8DF7;
    border-radius: 13px;

    caret-color:#1A8DF7;
}

.contactContainer .formContainer .left img {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}

.contactContainer .formContainer .right textarea {
    width: 605px;
    height: 208px;
    border: 2px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 13px;
    padding: 20px;
    resize: none;
    font-size: 16px;
    color: #707070;
}

.right .error {
    padding-left: 27px;
}

.contactContainer .formContainer .sendbtn {
    padding: 15px 54px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    border: none;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
    border-radius: 26px;
}
@media screen and (max-width: 1800px) {
    .contactContainer{
        padding-left: 350px;
    }
}


@media screen and (min-width: 1551px) {
    .contactContainer .formContainer .right .label{
        margin-left: 20px;
    }
    .contactContainer .formContainer .right textarea{
        width: 500px;
        margin-left: 20px;
    }
}


@media screen and (max-width: 1550px) {
    .contactContainer .formContainer .right .label {
        margin-left: 20px;
    }

    .contactContainer .formContainer .right textarea {
        width: 500px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 1450px) {
    .contactContainer {
        padding: 0 50px;
        gap: 50px;
    }
    .contactContainer .formContainer .left input {
        width: 90%;
    }
    .form_group {
        height: 150px;
    }


}
@media screen and (max-width: 1250px) {
    .contactContainer {
        /*padding: 0 30px;*/
        padding-bottom: 20px;
    }
    .contactContainer .formContainer .right textarea {
        width: 450px;
    }
}

    /*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1024px) {
    .contactContainer .formContainer {
        padding: 30px;
        box-shadow: 0 8px 15px rgb(55 142 241 / 10%);
    }
    .contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label{
        font-size: 18px;
    }

    .contactContainer .formContainer .right .label{
        margin-left: 0;
    }

    .contactContainer .formContainer .inputContainer {
        padding: 0;
    }

    .contactContainer .formContainer .left {
        width: calc(50% - 20px);
    }

    .contactContainer .formContainer .right {
        width: calc(50% - 20px);

    }
    .contactContainer .formContainer .left input{
        font-size: 15px;
    }
    .contactContainer .formContainer .right textarea {
        width: 100%;
        margin-left: 0;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .contactContainer .title {
        margin-left: 0px;
    }
    .contactContainer {
        padding: 0 30px 40px;
    }

    .contactContainer .formContainer {
        padding: 0;
        box-shadow: inherit;
        border-radius: inherit;
    }

    .contactContainer .formContainer .inputContainer {
        display: block;
        padding: 0;
        margin-bottom: 20px;

    }

    .contactContainer .formContainer .left {
        width: inherit;
    }

    .contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label {
        margin-left: 0;
        font-size: 14px;

    }

    .contactContainer .formContainer .left img {
        width: 24px;
        height: 24px;
    }

    .contactContainer .formContainer .left input {
        width: 100%;
        font-size: 14px;
        padding-left: 50px;
    }

    .contactContainer .formContainer .right {
        width: 100%
    }

    .contactContainer .formContainer .right textarea {
        width: 100%;
        margin-left: 0;
        font-size: 14px;
    }

    .contactContainer .formContainer .form_group {
        margin-bottom: 25px;
    }

    .form_group {
        height: 80px;
    }
}

@media screen and (max-width: 425px) {
    .contactContainer {
        max-width: 425px;
    }
    .title {
        font-size: 26px;
    }
}
@media screen and (max-width: 375px) {
    .contactContainer {
        max-width: 375px;
    }
    .title {
        font-size: 26px;
    }
}
@media screen and (max-width: 320px) {
    .contactContainer {
        max-width: 320px;
        margin: 0 auto;
    }
    .title {
        font-size: 24px;
    }
}