
.valueationTitleContainer {
    margin-bottom: 100px;
    text-align: center;
}

.valueationTitleContainer .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;

}

.valueationTitleContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    white-space: pre-wrap;
}
.valueationDesktop{
    display: block;
}
.valueationMobile{
    display: none;
}

.valueationTitleContainer.desktop {
    display: block;
}
.valueationTitleContainer.mobile {
    display: none;
}




.valueationContainer .itemBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 165px;
}

.valueationContainer .itemBox:last-child {
    margin-bottom: 0;
}

.valueationContainer .itemBox .video  {
    width: 654px;
    height: 374px;
    border-radius: 32px;
    filter: drop-shadow(0px 8px 30px rgba(55, 142, 241, 0.16));
}

.valueationContainer .itemBox .video video {
    width: 100%;
    height: 100%;
}

.valueationContainer .itemBox .contentBox.reverse .title, .valueationContainer .itemBox .contentBox.reverse .subTitle {
    text-align: left;
}

.valueationContainer .itemBox .contentBox .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    text-align: right;
    margin-bottom: 12px;
    display: block;
    white-space: pre-wrap;
    /*word-break: break-all*/
}

.valueationContainer .itemBox .contentBox .subTitle {
    text-align: right;
    font-size: 18px;
    line-height: 27px;
    display: block;
    white-space: pre-wrap;
    /*word-break: break-all*/
}

@media screen and (max-width: 1550px) {
    .valueationContainer .itemBox .video {
        width: 570px;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .valueationContainer {
        padding: 0 50px;
    }


    .valueationContainer .itemBox .video {
        width: 500px;
    }
    .valueationContainer .itemBox .contentBox .title{
        font-size: 26px;
        line-height: 32px;
        white-space: pre-wrap;

    }
    .valueationContainer .itemBox .contentBox .subTitle{
        font-size: 18px;
        line-height: 26px;
        white-space: pre-wrap;
    }
}

@media screen and (max-width: 1250px) {
    .valueationDesktop {
        /*padding: 0 30px;*/
    }
    .valueationContainer .itemBox .contentBox .title {
        font-size: 24px;
        line-height: 32px;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 16px;
        line-height: 26px;
        white-space: pre-wrap;
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .valueationContainer{
        margin-top: -100px;
    }
    .valueationContainer .itemBox{
        margin-bottom: 0;
    }
    .valueationContainer .itemBox .video {
        width: 350px;
        height: 355px;
        filter: drop-shadow(0px 8px 15px rgba(55, 142, 241, 0.1));
    }

    .valueationContainer .itemBox .contentBox .title{
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 0;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle{
        font-size: 15px;
        line-height: 24px;
        letter-spacing:-1px;
        white-space: pre-wrap;
    }
}

@media screen and (max-width: 768px) {
    .valueationTitleContainer.desktop {
        display: none;
    }
    .valueationTitleContainer.mobile {
        display: block;
    }
    .valueationDesktop{
        display: none;
    }
    .valueationMobile{
        display: block;
    }
    .valueationTitleContainer .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        white-space: pre-wrap;

    }
    .valueationTitleContainer .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        white-space: pre-wrap;
    }
    .valueationTitleContainer span {
        padding: 0 30px;
    }
    /* 모바일 가로, 타블렛 세로 */
    .title {
        font-size: 18px;
    }

    .valueationContainer {
        margin-top: -60px;
        padding: 0 30px
    }

    .valueationContainer .itemBox {
        display: block;
        margin-bottom: 40px;
    }

    .valueationContainer .itemBox .contentBox .title {
        font-size: 22px;
        line-height: 32px;
        white-space: pre-wrap;

    }

    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 16px;
        line-height: inherit;
        white-space: pre-wrap;
    }

    .valueationContainer .itemBox .contentBox.reverse .title, .valueationContainer .itemBox .contentBox.reverse .subTitle {
        text-align: center;
        /*white-space: pre;
        */
        /*padding: 0 13px;*/
        word-break: keep-all;
        /*white-space: revert;*/
        margin-bottom: 25px;
    }

    .valueationContainer .itemBox .video {
        /*width: 300px;*/
        width: 100%;
        height: 245px;
        margin: 0 auto;
        filter: inherit;
    }

    .valueationContainer .itemBox .contentBox.reverse .subTitle {
        line-height: 25px;
    }

}


@media screen and (max-width: 320px) {
    .valueationContainer .itemBox .contentBox .title {
        font-size: 19px;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 14px;
        white-space: pre-wrap;
    }
}