.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metaverseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metaverseContainer .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;
}
.metaverseContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 60px;
    white-space: pre-wrap;
}
.metaverseContainer .metaverseImg {
    text-align: center;
}
.metaverseContainer .metaverseImg img {
    width: 80%;
}

.metaverseContainer .title.mobile{
    display: none;
}
.metaverseContainer .subTitle.mobile{
    display: none;
}

@media screen and (max-width: 1550px){
    .metaverseContainer {
        padding-left: 350px;
    }
}
@media screen and (max-width: 1450px){
    .metaverseContainer {
        padding: 0px;
    }
    .metaverseContainer .title {
        font-size: 26px;
        line-height: 32px;
    }
    .metaverseContainer .subTitle {
        font-size: 18px;
        line-height: 26px;
    }
    .metaverseContainer .metaverseImg img {
        width: 50%;
    }

}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
}

@media screen and (max-width:1000px) {
    .metaverseContainer .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 32px;
    }
    .metaverseContainer .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 32px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width:768px) {
    .metaverseContainer .metaverseImg img {
    backface-visibility: hidden;
    }
    .metaverseContainer .title.mobile {
        display: block;
        font-weight: 700;
        font-size: 34px;
        line-height: 48px;
        text-align: center;
        white-space: pre-wrap;
    }
    .metaverseContainer .subTitle{
        display: none;
    }
    .metaverseContainer .subTitle.mobile {
        display: block;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        white-space: pre-wrap;

    }
    .metaverseContainer span {
        padding: 0 30px;
    }
    .metaverseImg {
        margin: 0 auto;
    }

    .metaverseContainer .title{
        display: none;
    }
    .metaverseContainer .subTitle{
        display: none;
    }
}
@media screen and (max-width:560px) {
    .metaverseContainer .title.mobile {
        font-size: 28px;
    }
}
@media screen and (max-width:425px) {
    .metaverseContainer{
        max-width: 425px;
    }
    .metaverseContainer .title.mobile {
        font-size: 24px;
        line-height: 34px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 18px;
        line-height: 25px;
    }
}
@media screen and (max-width:375px) {
    .metaverseContainer{
        max-width: 375px;
    }
    .metaverseContainer .title.mobile {
        font-size: 21px;
        line-height: 30px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 16px;
        line-height: 23px;
    }
}

@media screen and (max-width:320px) {
    .metaverseContainer{
        max-width: 320px;
    }
    .metaverseContainer .title.mobile {
        font-size: 16px;
        line-height: 27px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 14px;
    }
}
