.AdminLayout{
    background-color: #222831;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.AdminWrap{
    max-width: 1420px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.wrap.banner{*/
/*    max-width: 100%;*/
/*    height: 100%;*/
/*}*/
