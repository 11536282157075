.bannerContainer {
    background-image: url("assets/images/NftBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    padding-left: 300px;
    text-align: center;
    margin-bottom: 200px;
}

.bannerContainer span {
    display: block;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: #fff;
    margin-bottom: 50px;
}

@media screen and (max-width: 1550px) {

}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .bannerContainer {
        padding-left: 0;
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    .bannerContainer {
        margin-bottom: 100px;
        margin-top: -50px;
    }
}



@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .bannerContainer {
        margin-bottom: 100px;
        background-image: url("assets/images/NftBanner_mobile.png");
        background-size: cover;
        padding: 64px 0;
    }

    .bannerContainer span {
        font-size: 22px;
        margin-bottom: 22px;
        line-height: inherit;
    }

}


/*.bannerContainer button{*/
/*    width: 240px;*/
/*    height: 51px;*/
/*    color:#fff;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: block;*/
/*    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);*/
/*    box-shadow: 0px 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);*/
/*    border-radius: 26px;*/
/*    border: none;*/
/*    margin: 0 auto;*/

/*}*/
