.vueExplanContents {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 150px;
}

.vueExplanContents .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 0px;
    text-align: center;
    margin-bottom: 50px;
}

.vueExplanContents .subTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 50px;
    white-space: pre-wrap;
}

.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
}

.valueContents {
    display: block;
    text-align: center;
    margin-bottom: 0;
}
/*blue value text*/
.valueContents .value {
    display: block;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    text-decoration-line: underline;
    color: #1A8DF7;
    margin-bottom: 50px;
    white-space: pre-wrap;
}
.valueContents .title{
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    white-space: pre-wrap;
}
.valueContents .subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    white-space: pre-wrap;
}
.valueContents .title.mobile{
    display: none;
    white-space: pre-wrap;
}
.valueContents .subTitle.mobile{
    display: none;
    white-space: pre-wrap;
}

.vueExplanImg {
    margin: 50px 0 0 0;
    width: 80%;
}
.vueValueImg {
    margin: 100px 100px 120px 150px;
    width: 80%;
}
.vueExplanText.mobile {
    display: none;
}

@media screen and (max-width: 1450px){
    .backgroundMargin {
        padding-top: 150px;
    }

    .vueExplanContents .title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .vueExplanContents .subTitle {
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueExplanImg {
        margin-top: 30px;
    }
    .vueExplanImg img {
        width: 50%;
    }


    .valueContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .valueContents .subTitle {
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueValueImg {
        margin: 50px 150px 150px 150px
    }
    .vueValueImg img {
        width: 50%;
    }


    /*blue value text*/
    .valueContents .value {
        font-size: 32px;
        line-height: 32px;
    }

}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
    .vueValueImg {
        margin: 50px auto 150px;
    }
}


@media screen and (max-width:1000px) {
    .vueExplanImg {
        margin: 0 auto;
        padding-top: 30px;
    }
    .vueExplanContents {
        padding-top: 100px;
    }
    .vueValueImg {
        margin: 30px auto 100px;
    }


    .vueExplanContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .vueExplanContents .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .valueContents .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 46px;
        letter-spacing: -1px;
    }
    .valueContents .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueExplanImg img {
        width: 80%;
    }
    .vueValueImg img {
        width: 80%;
    }

}
@media screen and (max-width:900px) {
    .vueExplanImg img {
        width: 70%;
    }
    .vueValueImg img {
        width: 70%;
    }
}


@media screen and (max-width:768px) {
    .backgroundMargin {
        padding-top: 30px;
        padding-bottom: 20px
    }

    .valueContents .vueValueImg {
        margin: 0 auto;
    }
    .vueExplanText {
        display: none;
    }

    .vueExplanText.mobile {
        display: block;
    }

    .vueExplanText {
        padding: 0 30px;
    }

    .vueExplanText .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 23px;
        white-space: pre-wrap;
    }
    .vueExplanText .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }
    .vueExplanContents {
        padding-top: 50px;
    }
    .vueExplanContents .vueExplanImg {
        padding: 0 16px;
    }

    .vueExplanImg {
        margin: 22px 0 0;
        width: 100%;
    }
    .vueValueImg {
        /*margin: 12px 0 30px;*/
        margin: 10px auto;
        width: 100%;
    }
    .vueValueImg img {
        margin: 25px 0;
    }


    .valueContents .value {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        text-decoration-line: underline;
    }
    .valueContents .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }
    .valueContents .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        padding: 0 30px;
    }

    .valueContents .valueTextContents {
        padding: 0 30px;
    }

    .valueContents .vueValueImg {
        padding: 0 16px;
    }

    .valueContents .title.mobile{
        display: block;
    }
    .valueContents .subTitle.mobile{
        display: block;
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }

    .valueContents .title{
        display: none;
    }
    .valueContents .subTitle{
        display: none;
    }


    .valueContents .value {
        font-size: 36px;
        line-height: 45px;
    }

    .vueExplanText .title {
        font-size: 32px;
        line-height: 48px;
    }
    .vueExplanText .subTitle {
        font-size: 18px;
        line-height: 34px;
    }
    .valueContents .title {
        font-size: 32px;
        line-height: 48px;
    }
    .valueContents .subTitle.mobile {
        font-size: 18px;
        line-height: 34px;
    }
}
@media screen and (max-width:620px) {
    .vueExplanText .title {
        font-size: 30px;
        line-height: 48px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 34px;
    }
    .valueContents .title {
        font-size: 30px;
        line-height: 48px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 34px;
    }
}
@media screen and (max-width:560px) {

    .vueExplanText .title {
        font-size: 26px;
        line-height: 40px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 30px;
    }

    .valueContents .title {
        font-size: 26px;
        line-height: 40px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 30px;
    }
}

@media screen and (max-width:425px) {
    .vueExplanContents {
        max-width: 425px;
    }
    .valueContents {
        max-width: 425px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 15px 16px;
    }
    .vueExplanText .title {
        font-size: 28px;
        line-height: 32px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 29px;
    }
    .valueContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 29px;
    }

    .valueContents .value {
        font-size: 25px;
    }
}

@media screen and (max-width:375px) {
    .vueExplanContents {
        max-width: 375px;
    }
    .valueContents {
        max-width: 375px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 18px 16px;
    }
    .vueExplanText .title {
        font-size: 23px;
        line-height: 30px;
    }
    .vueExplanText .subTitle {
        font-size: 15px;
        line-height: 27px;
    }
    .valueContents .title {
        font-size: 23px;
        line-height: 30px;
    }
    .valueContents .subTitle.mobile {
        font-size: 15px;
        line-height: 27px;
    }

    .valueContents .value {
        font-size: 23px;
    }
}

@media screen and (max-width:320px) {

    .vueExplanContents {
        max-width: 320px;
    }
    .valueContents {
        max-width: 320px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 20px 16px;
    }
    .vueExplanText .title {
        font-size: 19px;
        line-height: 0px;
    }
    .vueExplanText .subTitle {
        font-size: 14px;
        line-height: 22px;
    }
    .valueContents .title.mobile {
        font-size: 19px;
        line-height: 27px;
    }
    .valueContents .subTitle.mobile {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -1.5px;
    }

    .valueContents .value {
        font-size: 22px;
    }

}