
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Noto+Sans+KR:wght@400;500;700&display=swap'); /* 영문 */

@import url('common.css');
@import url('Layout.css');
@import url('MainBanner.css');
@import url('Valueation.css');
@import url('Banner.css');
@import url('partner.css');
@import url('Contact.css');
@import url('Roadmap.css');
@import url('common.css');
@import url('Modal.css');
@import url('Story.css');
@import url('Metaverse.css');
@import url('ValuationTitle.css');
@import url('VueToken.css');
/*@import url('Illegal.css');*/
@import url('VueExplan.css');
@import url('VueEarn.css');



/* admin */
@import url('Admin/AdminLayout.css');
@import url('Admin/SignInLayout.css');
@import url('Admin/SignIn.css');
@import url('Admin/AdminMain.css');




*{margin: 0; padding:0; font-family: 'Montserrat','Noto Sans KR' , sans-serif; color:#333;}
input{appearance: none; -moz-appearance: none; /* Firefox */ -webkit-appearance: none; /* Safari and Chrome */ border-radius: 0;}
textarea{appearance: none; -moz-appearance: none; /* Firefox */ -webkit-appearance: none; /* Safari and Chrome */ border-radius: 0;}
