/*.keypartnersTitle {*/
/*    font-weight: 700;*/
/*    font-size: 38px;*/
/*    line-height: 46px;*/
/*    text-align: center;*/
/*    margin-bottom: 100px;*/
/*    padding-left: 225px;*/
/*}*/
.partnersContainer .itemBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 165px;
}

.partnersContainer .itemBox:last-child {
    margin-bottom: 0;
}

.partnersContainer .itemBox:nth-child(2) .contentBox {
    padding-top: 20px;
}
.partnersContainer .itemBox:nth-child(3) .contentBox {
    padding-top: 25px;
}
.partnersContainer .itemBox:nth-child(4) .contentBox {
    padding-top: 28px;
}
.partnersContainer .itemBox:nth-child(5) .partnerImage {
    padding-top: 20px;
}

.partnersContainer .itemBox .partnerImage{
    width: 805px;
    border-radius: 32px;
    /*filter: drop-shadow(0px 8px 30px rgba(55, 142, 241, 0.16));*/
    text-align: center;
    /*padding-top: 30px;*/
}

.partnersContainer .itemBox .partnerImage .imageTitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: block;
    padding-top: 25px;
    margin-bottom: 40px;
}

.partnersContainer .itemBox .contentBox .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 60px;
    display: block;
    text-align: start;
}

.partnersContainer .itemBox .contentBox .companyName {
    font-weight: bold;
    display: block;
    font-size: 20px;
    margin-bottom: 20px;
}

.partnersContainer .itemBox .contentBox .contentText {
    font-size: 15px;
    line-height: 24px;
    display: block;
    white-space: pre-wrap;
    word-break: break-all
}

.partnersContainer.mobile {
    display: none;
}
.partnersContainer.tablet {
    display: none;
}

@media screen and (max-width: 1800px) {
    .partnersContainer {
        padding-left: 350px;
    }
    .partnersContainer .itemBox .partnerImage {
        width: 670px;
        height: auto;
    }
}

@media screen and (max-width: 1700px) {
    .partnersContainer .itemBox .contentBox .contentText{
        font-size: 14px;
    }
    .partnersContainer .itemBox .partnerImage {
        width: 630px;
        height: auto;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
        /*padding-left: 130px;*/
    }

    .partnersContainer.tablet {
        /*max-width: 1200px;*/
        padding: 0 50px;

    }

    .partnersContainer .itemBox .partnerImage{
        width: 550px;
    }
    .partnersContainer .itemBox .contentBox .title{
        font-size: 26px;
        margin-bottom: 30px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 18px;
        line-height: 70px;
        margin-bottom: 0;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 16px;
        line-height: 26px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 1250px) {
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
    }

    .partnersContainer {
        padding: 0 50px;
        /*max-width: 1000px;*/
    }
    .partnersContainer .itemBox .contentBox .title {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 16px;
        margin-bottom: 0;
    }
    .partnersContainer .itemBox .contentBox .contentText{
        font-size: 13px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle{
        font-size: 18px;
    }
    .partnersContainer .itemBox .partnerImage{
        width: 400px;
    }
}

@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
    }

    .partnersContainer .itemBox .contentBox .title {
        font-size: 24px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 19px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 15px;
        letter-spacing: -1px;
        width: 400px;
    }

}

@media screen and (max-width: 768px) {
    /*.keypartnersTitle {*/
    /*    text-align: left;*/
    /*    font-weight: 700;*/
    /*    font-size: 24px;*/
    /*    margin-bottom: 32px;*/
    /*    padding-left: 40px;*/
    /*}*/
    .partnersContainer {
        padding: 0 30px;
    }
    .partnersContainer .itemBox {
        display: block;
        text-align: center;
        margin-bottom: 100px;
    }
    .partnersContainer .itemBox .partnerImage {
        margin: 0 auto;
        width: 100%;
    }
    .partnersContainer .itemBox .contentBox .title{
        line-height: inherit;
        margin-bottom: 20px;
        text-align: center;
        font-size: 34px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 70px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        word-break: keep-all;
        white-space: pre-wrap;
        margin-bottom: 25px;
        font-size: 24px;
        line-height: 36px;
        padding: 0 1px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 28px;
        line-height: 70px;
        margin-bottom: 17px;
    }

    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.tablet {
        display: none;
    }
    .partnersContainer.mobile {
        display: block;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 767px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}

@media screen and (max-width: 700px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}

@media screen and (max-width: 600px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}
@media screen and (max-width: 560px) {
    .partnersContainer .itemBox .contentBox .title {
        font-size: 24px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 20px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: -1.5px;
        padding: 0px 1px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 20px;
        line-height: 26px;
    }
}


@media screen and (max-width: 425px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 21px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 18px;
        line-height: 32px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 16px;
        line-height: 28px;
        padding: 0 1px;
        margin-bottom: 0px;
        letter-spacing: 0px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 375px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 20px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 17px;
        line-height: 31px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 14px;
        line-height: 22px;
        padding: 0 1px;
        margin-bottom: 0px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 320px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 19px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 16px;
        line-height: 30px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 12.9px;
        line-height: 20px;
        padding: 0 1px;
        margin-bottom: 0px;
        width: auto;
        letter-spacing: -1px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 319px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 15px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 13px;
        line-height: 20px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 11px;
        line-height: 20px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 13px;
        line-height: 20px;
    }
}