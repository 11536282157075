.storyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.storyContainer.mobile{
    display: none;
}
.storyContainer.tablet {
    display: none;
}

/*.storyContainer:nth-child(2) {*/
/*    padding-left: 50px;*/
/*}*/
.storyContainer .storyContents .redTitle {
    display: block;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    color: #F63657;
    white-space: pre-wrap;

}

.storyContainer .title {
    display: block;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 30px 0;
    white-space: pre-wrap;
}

.storyContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    word-break: keep-all;
    margin: 0 auto;
    margin-bottom: 60px;
    white-space: pre-wrap;
}

.storyContainer .storyImage {
    display: block;
    /*max-width: 600px;*/
    /*max-height: 426px;*/
    text-align: center;
    /*padding-left: 100px;*/
    /*box-sizing: border-box;*/
}

@media screen and (max-width: 1450px){
    .storyContainer.tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .storyContainer.mobile{
        display: none;
    }
    .storyContainer {
        display: none;
    }
    .storyContainer.tablet .title {
        display: block;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        white-space: pre-wrap;
    }
    .storyContainer.tablet .subTitle {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -1.5px;
        text-align: center;
        white-space: pre-wrap;
        margin-bottom: 10px;
    }
    .storyContainer.tablet .storyImage {
        padding: 30px 100px 0;
    }
}

@media screen and (max-width:1250px) {
    /* 타블렛 가로 */
    .storyContainer.tablet .title {
        font-size: 22px;
        line-height: 29px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 15.5px;
        line-height: 27px;
    }
}
@media screen and (max-width:1024px) {
    .storyContainer.tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .storyContainer.mobile{
        display: none;
    }
    .storyContainer {
        display: none;
    }
    .storyContainer.tablet .title {
        font-weight: 900;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        white-space: pre-wrap;
    }
    .storyContainer.tablet .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
        letter-spacing: -1.5px;
        white-space: pre-wrap;
    }

}
@media screen and (max-width:1000px) {
    .storyContainer.tablet .title {
        font-size: 20.5px;
        line-height: 27.5px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 14.5px;
        line-height: 25.5px;
    }
}
@media screen and (max-width:900px) {
    .storyContainer.tablet .title {
        font-size: 20px;
        line-height: 27px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 14px;
        line-height: 25px;
    }
}

@media screen and (max-width:768px) {
    .storyContainer {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*text-align: center;*/
        /*margin-bottom: 50px;*/
        /*padding: 0 16px;*/
        display: none;
    }
    .storyContainer.tablet {
        display: none;
    }

    .storyContainer.mobile{
        display: block;
        padding: 0 30px;
    }
    .storyContents.textContents{
        display: none;
    }

    .storyContainer.mobile .title {
        display: block;
        font-weight: 700;
        font-size: 28px;
        line-height: 45px;
        text-align: center;
        white-space: pre-wrap;
        margin: 30px 0;
    }
    .storyContainer.mobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        text-align: center;
        white-space: pre-wrap;
        margin-bottom: 10px;
    }
    .storyContainer .storyImage {
        /*width: 80%;*/
        /*height: auto;*/
        padding: 33px 0;
    }

    .storyContainer .storyImage img {
        width: 70%;
    }
}

@media screen and (max-width:560px) {
    .storyContainer.mobile .title {
        font-size: 23px;
        line-height: 50px;
    }
    .storyContainer.mobile .subTitle {
        font-size: 18px;
        line-height: 30px;
    }
}

@media screen and (max-width:425px) {
    .storyContainer {
        max-width: 425px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:375px) {
    .storyContainer {
        max-width: 375px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 23px;
        line-height: 33px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:320px) {
    .storyContainer {
        max-width: 320px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 19px;
        line-height: 30px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 10px;
    }
}