/* position */
.p_relative {
    position: relative;
}

/* margin */
.mb_50 {
    margin-bottom: 50px;
}

/* title */
.title {
    display: block;
    font-weight: bold;
    font-size: 38px;
    margin-bottom: 50px;
}

/* profileItem */
.teamAContainer .title {
    text-align: start;
}

.teamAContainer .profileBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

.teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
    height: 288px;
    padding: 30px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
}


.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*align-items: center;*/
}
.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage span {
    bottom: 20px;
}

.teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
}

.teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
    width: 80px;
    height: 80px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox .profile .nameImage .profileImage img, .adviserContainer .profileBox .profile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox .profile .nameImage .position, .adviserContainer .profileBox .profile .nameImage .position {
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: #1A8DF7;
    padding-top: 5px;
}

.teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
    font-size: 15px;
    line-height: 24px;
    white-space: pre-wrap;
    word-break: break-all;
}

/* profileItem 모바일 */
.teamAContainer .profileBox_mobile .profile_group, .adviserContainer .profileBox_mobile .profile_group {
    width: 100%;
    /*height: 328px;*/
    padding-bottom: 20px;
    box-sizing: border-box;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile, .adviserContainer .profileBox_mobile .profile_group .profile_mobile {
    width: calc(100% - 23px);
    height: 180px;
    margin: 0 auto;
    margin-bottom: 23px;
    background: #FFFFFF;
    /* picture shadow */
    box-shadow: 0 8px 30px rgba(55, 142, 241, 0.1);
    border-radius: 26px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0), .adviserContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0) {
    margin-bottom: 0;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage {
    /*width: 100%;*/
    width: calc(340px - 90px);
    height: 84px;
    background-color: #fff;

}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    width: 70px;
    height: 70px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .name, .profileBox_mobile .profile_group .profile_mobile .nameImage .name {
    font-weight: bold;
    font-size: 17px;
    display: block;
    margin-bottom: 10px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .position, .profileBox_mobile .profile_group .profile_mobile .nameImage .position {
    font-weight: bold;
    font-size: 14px;
    color: #1A8DF7;
    display: block;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .profileHistory, .profileBox_mobile .profile_group .profile_mobile .profileHistory {
    width: calc(340px - 80px);
    /*min-height: 82px;*/
    background-color: #fff;
    text-align: center;
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    line-height: 19px;
    letter-spacing: -1px;
}

.teamContentsMobile {
    display: none;
}

/*.teamAContainer {*/
/*    max-width: 1630px;*/
/*}*/

/* Create NFT button */
.CreateNFT {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

.CreateNFTcontainer {
    width: 240px;
    height: 55px;
    margin: 0 auto;
}

/*.CreateNFT_shadow{*/
/*    width: 240px;*/
/*    height: 51px;*/
/*    color:#fff;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: block;*/
/*    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);*/
/*    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0 5px 5px rgba(255, 255, 255, 0.3);*/
/*    border-radius: 26px;*/
/*    border: none;*/
/*    margin: 0 auto;*/
/*}*/
.CreateNFT_shadow {
    display: block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.CreateNFTActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* SendMessage */
/*.SendMessage {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #3755F1;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*    position: relative;*/
/*}*/

/*.SendMessage .subBackground {*/
/*    width: 236px;*/
/*    height: 46px;*/
/*    line-height: 46px;*/
/*    background-color: #fff;*/
/*    color: #3755F1;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    border-radius: 26px;*/
/*}*/

/*.SendMessageActive {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #fff;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*}*/
.SendMessage {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

/*.SendMessageTcontainer {*/
/*    width: 240px;*/
/*    height: 55px;*/
/*    margin: 0 auto;*/
/*}*/

.SendMessage_shadow {
    display: inline-block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.SendMessageActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* White Paper */
.whitePaperDown {
    background: rgba(36, 124, 238, 0.1);
    border-radius: 23px;
    border: none;
    padding: 10px 24px;
    cursor: pointer;
}

.whitePaperDown .whitePaperTitle {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: #1A8DF7;
    font-weight: bold;
    font-size: 15px;
}

.whitePaperDown img {
    display: inline-block;
    vertical-align: middle;
    width: 17.5px;
    height: 18px;
}

.whitePaperDown:hover img {
    position: relative;
    animation: slideArrow 1.1s linear infinite;
}

.whitePaperDown.sideMenuWhitePaper {
    background-color: inherit;
    border-radius: inherit;
    margin-bottom: 30px;
    padding: 0;
}
@media screen and (max-width: 1800px) {
    .teamAContainer {
        padding-left: 350px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
    }
}


@media screen and (min-width: 1551px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1550px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .wrap {
        max-width: 1250px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer {
        padding: 0 50px;
    }



}
@media screen and (max-width: 1250px) {
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 11.9px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 15px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 14px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 50px;
        gap: 30px;
    }
}


/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1024px) {
    .teamAContainer {
        margin-top: 150px;
        padding: 0 50px;
    }

    .teamAContainer .title {
        font-size: 24px;
        /*margin-left: 25px;*/
    }

    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
        padding: 20px 30px;
        box-shadow: 0 8px 15px rgb(55 142 241 / 10%);
    }

    .teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
        font-size: 18px;
    }

    .teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
        width: 70px;
        height: 70px;
    }

    .teamAContainer .profileBox {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
    }

    .SendMessage {
        filter: drop-shadow(0px 8px 15px rgba(55, 85, 241, 0.1));
    }

    .whitePaperDown {
        width: 186px;
        height: auto;
    }
    .footerContainer .footerContentBox .snsLink .sns {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .teamContentsDesktop {
        padding-bottom: 50px;
    }
}
@media screen and (max-width: 999px) {
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
        gap: 30px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */

    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }

    .teamAContainer {
        padding: 0 30px;
    }
    .teamContentsDesktop {
        display: none;
    }
    .teamContentsMobile {
        display: block;
    }

    .SendMessageActive {
        width: 150px;
        height: 35px;
        font-size: 14px;
        color: #FFFFFF;
    }

    .SendMessage .subBackground {
        width: 146px;
        height: 32px;
        line-height: 31px;
        font-size: 14px;
    }

    .SendMessage {
        width: 150px;
        height: 35px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 23px;
    }

    .CreateNFT_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow .subBackground {
        width: 185px;
        height: 40px;
        line-height: 40px;
    }

}
@media screen and (max-width: 560px) {
    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }
}


@media screen and (max-width: 425px) {
    .teamAContainer {
        max-width: 425px;
    }
    .teamAContainer .title {
        font-size: 26px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 23px;
    }
}
@media screen and (max-width: 375px) {
    .teamAContainer {
        max-width: 375px;
    }
    .teamAContainer .title {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 22px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 21px;
    }
}
@media screen and (max-width: 320px) {

    .teamAContainer {
        max-width: 320px;
        padding: 0;
    }
    .teamAContainer .title {
        font-size: 21px;
        padding-left: 10px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 20px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 19px;
    }
}