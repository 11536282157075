.roadmapContainer{
    margin-bottom: 198px;
}

.roadmapImageMobile{
    display: none;
}

.roadmapContainer .roadmap{
    /*background-image: url("assets/images/roadmapBar.png");*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*width: 100%;*/
    /*height: 3px;*/
}

.roadmapContainer .title{
    /*max-width: 1420px;*/
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px;
    /*padding-left: 150px;*/
    box-sizing: border-box;
    text-align: start;
    margin-top: 150px;
}


/*.roadmapContainer img {*/
/*    width: 1420px;*/
/*    height: auto;*/
/*    !*padding-left: 390px;*!*/
/*}*/
@media screen and (max-width: 1800px) {
    .roadmapContainer {
        padding-left: 350px;
        backface-visibility: hidden;
    }
}

@media screen and (max-width: 1700px) {
    .roadmapContainer {
        max-width: 1550px;
    }
}

@media screen and (max-width: 1550px){
    /*.roadmapContainer .title{*/
    /*    padding-left: 300px;*/
    /*}*/
}
@media screen and (max-width:1450px){
    .roadmapContainer {
        padding: 0 50px;
        max-width: 1200px;
        margin-bottom: 150px;
    }
    .roadmapContainer .title {
        margin-bottom: 50px;
        margin-top: 135px;
    }
}
@media screen and (max-width: 1250px) {
    .roadmapContainer .title{
        margin-bottom: 50px;
    }
}

/*@media screen and (max-width: 860px){*/
@media screen and (max-width: 1000px){
    .roadmapContainer{
        margin-bottom: 100px;
        max-width: 900px;
    }
    .roadmapContainer .title{
        /*padding-left: 0px;*/
        margin-bottom: 50px;
    }
    .roadmapPC{
        display: none;
    }
    .roadMapTablet{
        display: block;
    }
    .roadMapMobile{
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .roadmapContainer{
        max-width: 850px;
    }
}
@media screen and (max-width: 800px) {
    .roadmapContainer{
        max-width: 750px;
    }
}

@media screen and (max-width:768px){
    .roadmapContainer {
        max-width: 768px;
        padding: 0;
    }

    .roadmapImageDesktop{display: none}
    .roadmapImageMobile{
        display: block;
    }
    .roadmapContainer {
        margin-bottom: 150px;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 30px;
        margin: 60px 0 0;
        font-size: 30px;
    }
    .roadmapImage {
        margin: 0 auto;
    }
    .roadmapPC{
        display: none;
    }
    .roadMapTablet{
        display: none;
    }
    .roadMapMobile{
        display: block;
    }
}
@media screen and (max-width:425px) {
    .roadmapContainer {
        max-width: 425px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 20px;
    }
}
@media screen and (max-width:375px) {
    .roadmapContainer {
        max-width: 375px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 19px
    }

}
@media screen and (max-width:320px) {
    .roadmapContainer {
        max-width: 320px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 19px
    }

}

