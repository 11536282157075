@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&family=Noto+Sans+KR:wght@400;500;700&display=swap);
/* position */
.p_relative {
    position: relative;
}

/* margin */
.mb_50 {
    margin-bottom: 50px;
}

/* title */
.title {
    display: block;
    font-weight: bold;
    font-size: 38px;
    margin-bottom: 50px;
}

/* profileItem */
.teamAContainer .title {
    text-align: start;
}

.teamAContainer .profileBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
}

.teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
    height: 288px;
    padding: 30px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
}


.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*align-items: center;*/
}
.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage span {
    bottom: 20px;
}

.teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
}

.teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
    width: 80px;
    height: 80px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox .profile .nameImage .profileImage img, .adviserContainer .profileBox .profile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox .profile .nameImage .position, .adviserContainer .profileBox .profile .nameImage .position {
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: #1A8DF7;
    padding-top: 5px;
}

.teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
    font-size: 15px;
    line-height: 24px;
    white-space: pre-wrap;
    word-break: break-all;
}

/* profileItem 모바일 */
.teamAContainer .profileBox_mobile .profile_group, .adviserContainer .profileBox_mobile .profile_group {
    width: 100%;
    /*height: 328px;*/
    padding-bottom: 20px;
    box-sizing: border-box;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile, .adviserContainer .profileBox_mobile .profile_group .profile_mobile {
    width: calc(100% - 23px);
    height: 180px;
    margin: 0 auto;
    margin-bottom: 23px;
    background: #FFFFFF;
    /* picture shadow */
    box-shadow: 0 8px 30px rgba(55, 142, 241, 0.1);
    border-radius: 26px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0), .adviserContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0) {
    margin-bottom: 0;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage {
    /*width: 100%;*/
    width: calc(340px - 90px);
    height: 84px;
    background-color: #fff;

}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    width: 70px;
    height: 70px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .name, .profileBox_mobile .profile_group .profile_mobile .nameImage .name {
    font-weight: bold;
    font-size: 17px;
    display: block;
    margin-bottom: 10px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .position, .profileBox_mobile .profile_group .profile_mobile .nameImage .position {
    font-weight: bold;
    font-size: 14px;
    color: #1A8DF7;
    display: block;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .profileHistory, .profileBox_mobile .profile_group .profile_mobile .profileHistory {
    width: calc(340px - 80px);
    /*min-height: 82px;*/
    background-color: #fff;
    text-align: center;
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    line-height: 19px;
    letter-spacing: -1px;
}

.teamContentsMobile {
    display: none;
}

/*.teamAContainer {*/
/*    max-width: 1630px;*/
/*}*/

/* Create NFT button */
.CreateNFT {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

.CreateNFTcontainer {
    width: 240px;
    height: 55px;
    margin: 0 auto;
}

/*.CreateNFT_shadow{*/
/*    width: 240px;*/
/*    height: 51px;*/
/*    color:#fff;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: block;*/
/*    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);*/
/*    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0 5px 5px rgba(255, 255, 255, 0.3);*/
/*    border-radius: 26px;*/
/*    border: none;*/
/*    margin: 0 auto;*/
/*}*/
.CreateNFT_shadow {
    display: block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.CreateNFTActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* SendMessage */
/*.SendMessage {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #3755F1;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*    position: relative;*/
/*}*/

/*.SendMessage .subBackground {*/
/*    width: 236px;*/
/*    height: 46px;*/
/*    line-height: 46px;*/
/*    background-color: #fff;*/
/*    color: #3755F1;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    border-radius: 26px;*/
/*}*/

/*.SendMessageActive {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #fff;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*}*/
.SendMessage {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

/*.SendMessageTcontainer {*/
/*    width: 240px;*/
/*    height: 55px;*/
/*    margin: 0 auto;*/
/*}*/

.SendMessage_shadow {
    display: inline-block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.SendMessageActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* White Paper */
.whitePaperDown {
    background: rgba(36, 124, 238, 0.1);
    border-radius: 23px;
    border: none;
    padding: 10px 24px;
    cursor: pointer;
}

.whitePaperDown .whitePaperTitle {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: #1A8DF7;
    font-weight: bold;
    font-size: 15px;
}

.whitePaperDown img {
    display: inline-block;
    vertical-align: middle;
    width: 17.5px;
    height: 18px;
}

.whitePaperDown:hover img {
    position: relative;
    -webkit-animation: slideArrow 1.1s linear infinite;
            animation: slideArrow 1.1s linear infinite;
}

.whitePaperDown.sideMenuWhitePaper {
    background-color: inherit;
    border-radius: inherit;
    margin-bottom: 30px;
    padding: 0;
}
@media screen and (max-width: 1800px) {
    .teamAContainer {
        padding-left: 350px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
    }
}


@media screen and (min-width: 1551px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1550px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .wrap {
        max-width: 1250px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer {
        padding: 0 50px;
    }



}
@media screen and (max-width: 1250px) {
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 11.9px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 15px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 14px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 50px;
        grid-gap: 30px;
        gap: 30px;
    }
}


/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1024px) {
    .teamAContainer {
        margin-top: 150px;
        padding: 0 50px;
    }

    .teamAContainer .title {
        font-size: 24px;
        /*margin-left: 25px;*/
    }

    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
        padding: 20px 30px;
        box-shadow: 0 8px 15px rgb(55 142 241 / 10%);
    }

    .teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
        font-size: 18px;
    }

    .teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
        width: 70px;
        height: 70px;
    }

    .teamAContainer .profileBox {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        gap: 20px;
    }

    .SendMessage {
        -webkit-filter: drop-shadow(0px 8px 15px rgba(55, 85, 241, 0.1));
                filter: drop-shadow(0px 8px 15px rgba(55, 85, 241, 0.1));
    }

    .whitePaperDown {
        width: 186px;
        height: auto;
    }
    .footerContainer .footerContentBox .snsLink .sns {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .teamContentsDesktop {
        padding-bottom: 50px;
    }
}
@media screen and (max-width: 999px) {
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
        grid-gap: 30px;
        gap: 30px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */

    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }

    .teamAContainer {
        padding: 0 30px;
    }
    .teamContentsDesktop {
        display: none;
    }
    .teamContentsMobile {
        display: block;
    }

    .SendMessageActive {
        width: 150px;
        height: 35px;
        font-size: 14px;
        color: #FFFFFF;
    }

    .SendMessage .subBackground {
        width: 146px;
        height: 32px;
        line-height: 31px;
        font-size: 14px;
    }

    .SendMessage {
        width: 150px;
        height: 35px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 23px;
    }

    .CreateNFT_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow .subBackground {
        width: 185px;
        height: 40px;
        line-height: 40px;
    }

}
@media screen and (max-width: 560px) {
    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }
}


@media screen and (max-width: 425px) {
    .teamAContainer {
        max-width: 425px;
    }
    .teamAContainer .title {
        font-size: 26px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 23px;
    }
}
@media screen and (max-width: 375px) {
    .teamAContainer {
        max-width: 375px;
    }
    .teamAContainer .title {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 22px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 21px;
    }
}
@media screen and (max-width: 320px) {

    .teamAContainer {
        max-width: 320px;
        padding: 0;
    }
    .teamAContainer .title {
        font-size: 21px;
        padding-left: 10px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 20px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 19px;
    }
}
.Layout {
    background-color: #fff;
    width: 100%;
    /*height: 100vh;*/
    position: relative;
}

.wrap {
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 150px;
    padding-left: 300px;
    /*box-sizing: border-box;*/
}

.footerContainer {
    background: rgba(55, 142, 241, 0.06);
    padding: 45px 30px;
}

.footerContainer .footerContentBox {
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerContainer .footerContentBox .tabletFooter {
    display: none;
}

.footerContainer .footerContentBox .defaultFooter {
    /*display: block;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footerContainer .footerContentBox .whitePaper .labelLogo{
    width: 89px;
    height: 53px;
    margin-bottom: 20px;
}


/*.footerContainer .footerContentBox .whitePaper .whitePaperDown {*/
/*    background: rgba(36, 124, 238, 0.1);*/
/*    border-radius: 23px;*/
/*    border: none;*/
/*    padding: 10px 24px;*/
/*    cursor: pointer;*/

/*}*/

.footerContainer .footerContentBox .whitePaper .whitePaperDown span{
    display: inline-block;
    vertical-align: middle;
    margin-right:8px;
    color: #1A8DF7;
    font-weight: bold;
    font-size: 15px;
}
/*.footerContainer .footerContentBox .whitePaper .whitePaperDown span {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    margin-right: 8px;*/
/*    color: #3755F1;*/
/*    font-weight: bold;*/
/*    font-size: 15px;*/
/*}*/

/*.footerContainer .footerContentBox .whitePaper .whitePaperDown img {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    width: 17.5px;*/
/*    height: 18px;*/
/*}*/

/*.footerContainer .footerContentBox .whitePaper .whitePaperDown:hover img {*/
/*    position: relative;*/
/*    animation: slideArrow 1.1s linear infinite;*/
/*}*/


@-webkit-keyframes slideArrow {
    0% {
        left: 2px;
    }
    50% {
        left: 10px;
    }
    100% {
        left: 2px;
    }
}


@keyframes slideArrow {
    0% {
        left: 2px;
    }
    50% {
        left: 10px;
    }
    100% {
        left: 2px;
    }
}

.footerContainer .footerContentBox .footerTitle {
    font-weight: bold;
    font-size: 15px;
    color: #333;
    margin-bottom: 20px;
    display: block;
}

.footerContainer .footerContentBox .content {
    font-size: 15px;
    color: #333;
    display: block;
    margin-bottom: 10px;
}

.footerContainer .footerContentBox .content:last-child {
    margin-bottom: 0;
}

.footerContainer .footerContentBox .snsLink .sns {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    background-color: #fff;
}

.footerContainer .footerContentBox .snsLink .sns:last-child {
    margin-right: 0;
}

.footerContainer .footerContentBox .snsLink .sns.twitter {
    background-image: url(/static/media/twitter_gray.cf51ceb1.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.twitter:hover {
    background-image: url(/static/media/twitter_blue_icon.207247fe.svg);
}


.footerContainer .footerContentBox .snsLink .sns.telegram {
    background-image: url(/static/media/telegram_gray.42906a31.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.telegram:hover {
    background-image: url(/static/media/telegram_blue_icon.da357587.svg);

}

.footerContainer .footerContentBox .snsLink .sns.discord {
    background-image: url(/static/media/discord_gray.e624e4cf.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.discord:hover {
    background-image: url(/static/media/discord_blue_icon.71575bfd.svg);
}

.footerContainer .footerContentBox .snsLink .sns.kakao {
    background-image: url(/static/media/kakao_gray.c71aa2d1.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.kakao:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAT0SURBVHgBxVhNbBtFFH4zdvwTlMQcqBKaIFfthebQwqWES50DJFekEk5IAYVznaY9cEp84lASuwdORKKIUwISglMCBzuXhF7ASLinICwllaNwqOtKtuOfmb4367Udd70767rpJ1nr3Zm38+17b743Mwx6xPjdUgQ87IqQIgKMXWUgQ1KyELUxJvMSeBakyOJdmovazuGdoRT0AOamcyguQ4OiHEUCN00yLkZCwpASHGJHi8GsvpkmsddkaQVJ3YT+4L4uUUeC51eLUey17NpjziNnGXhWHt3yfW/fzY7cWjHRR69ZQoJM5JYGF7u1WxI0Qlr+WUqIwBlAMpYuMf90fpHlO9u4lcGgPEmeFTkCk/JqEB1i1fYcQQoroAGcMRg6ZGy1GH/uefvN+dXSvAT4Dl4hMCcXMScT5n2T4Gi8FOYSktgjDC4xPsxggn4jxusOnkgoVCRkjiW4h8wXefCCmY9e8zEXsIyXsO5rpiY4zFz0wCeTHIb91mJwUJCwdyBgM1OHvUMBemChoCgtIzs1s9WblfcE/KdjTp6KRbwwc8kDbkBEo9tVOCzoebXIA6+TF9UkaXjPEZNvMPjtU59rcgTy+O9o+/FlPVssqUp/jVnMnCWFyP005+saTh2QbWJ2QJOkjCpqtCrBupi060phJXI0GfqBwomED36oOIZbiNo0R3LXwQEU0m7kaDD6td93tnf+J08uTXnBCYwNXMEQM0dR/uJdj3r529+coJCXm78bP1bgxmYFrq1XVL9dnAjUpx3UvrUvml779s+6ej57iTunCxMRzpi9tNBLyHt0pSRfmxlQzx8s+GHpPS9k/je80k1GDgqG5z7/pYp5zNXHmu+dGAZ7fug8jqvesF2nEX/rvynI5v8N1DcK/xxqIWmdFYjc2l5NfUBnWJ1zWoawePS+zqNBJ4bQuz7WDKMVhvEjp8Y5LO9U4TR5pxFYyDFTqRrQwJ35srVfV7OQSlrBSEHY/tcI824j3G8NGTax6QEqsipnt/frTR0108MOXtzs5J28SIlthodCTmQpt+YmPRBv5KSEKjwpS5g8x2Bxy/AU1WYzjCTUC5h/Gw+FIkgp0c3jLcg8e3O1RCUubNeNCD1YeDGR7sS19RNHHZRSpklm0uAANQt/rUK/sJzUrMm4b+E4S1KgASr2FDrnsDiTW/+rrtWX0Z66JuXfoInNh3X4EMV2V3vp1AKJOIm2LjmCYJBSSYVby8du5Ia08I8Fv2M/8vY/xy0ddAOMUza3FLzgNW48uMQWK7rGl8+d3sqQdGxkBIwEWs8yx6JZRXoBei5FV0XQx333KqK8oms8e9EgSINHt2qof/ph04WoQIyuaqQsrlwxIRO6xu+jppHXaJHwMsgh7h99aRyLNGM1wP0xEm0d6693a0p2XnRGW4Fyz/Qe4ZTyjt0tRhlncXiFEBLmj24Hm+c1p7I9d2cw4SbU/QYqSaKdHMGydo2tlZLsDI8+CHhIlc7dDrzT+dzybMbPAh/hetuxBPYLeA6UKj31T1u12R+/rZbjsrG7elmgsOaWAl2P37id8SM0FPX6ZzSzoO+Q+bpkUTtyBK310+hXePLgU5v7eegDyGulgj+WjzFHWXO1wDOJokcjzMU5ToNWXiCxciFwT4dYTwTbMbpWvc5lLWKcJfIwbhHD0FxwqFV6Hg8m0/gxKclZ+uhWcAd6wDMLgDgfROI6AQAAAABJRU5ErkJggg==);
}


.footerContainer .footerContentBox .snsLink .sns.medium {
    background-image: url(/static/media/medium_gray.14025a51.svg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footerContainer .footerContentBox .snsLink .sns.medium:hover{
    background-image: url(/static/media/medium_blue_icon.728f40a9.svg);

}

/* sideMenu */
.sideMenu {
    width:300px;
    position: fixed;
    top: 90px;
    left: 40px;
    z-index: 100;
    padding: 40px 30px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
}

.sideMenu .topContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}


.sideMenu .labelLogo{
    width: 95px;
    height: 53px;

    -webkit-backface-visibility: hidden;

            backface-visibility: hidden;
}
.langContainer{
    border: 1px solid #C9C9C9;
    border-radius: 14px;
    width: 84px;
    height: 28px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.langContainer.langMobile{
    width: 72px;
    height: 24px;
    /*margin-right: 20px;*/
}
.langContainer.langMobile a{
    font-size: 12px;
}
.langContainer a{
    font-size: 14px;
    font-weight: 500;
    color: #707070;
    text-decoration: none;
}
.langContainer a.selected{
    color:#1A8DF7;
}

.sideMenu span {
    display: block;
    color: #333;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}

.sideMenu .sideActive span {
    color: #1A8DF7;
}

.mobileSideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background-color: #fff;
    z-index: 3;
    display: none;
}

.mobileSideMenu.menuAnimateLeft {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: none;
            animation-fill-mode: none;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-name: animateleft;
            animation-name: animateleft;
}

.mobileSideMenu .menuTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 2px solid #F1F1F1;
}

.mobileSideMenu .menuTop .labelLogo {
    height: 40px;
}
.mobileSideMenu .menuTop .labelLogo img {
    width: 80px;
    height: auto;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.mobileSideMenu .menuTop .menuClose {
    width: 30px;
    height: 30px;
}



.mobileSideMenu .menuList{

    padding: 30px 24px;
}

.mobileSideMenu .menuList li {
    list-style: none;
    margin-bottom: 30px;
}

.mobileSideMenu .menuList li:last-child {
    margin-bottom: 40px;
}

.mobileSideMenu .menuList .navName {
    /*display: block;*/
    color: #707070;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}



.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: none;
}

/* navigation */
.navigation {
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1;
    border: 1px solid #F1F1F1;
    display: none;
}

.navigationContainer {
    /*max-width: 360px;*/
    margin: 0 auto;
    padding: 10px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navigationContainer .menuIcon {
    width: 75px;
    /*width: 30px;*/
    /*height: 30px;*/
    /*float: left;*/
    /*margin-left: 20px;*/
}

.navigationContainer .logoSmall {
    width: 75px;
    height: 28px;
    margin: 0 auto;
    box-sizing: border-box;
    /*backface-visibility: hidden;*/
}

/*.partnersContainer {*/
/*    max-width: 1630px;*/
/*}*/


@media screen and (max-width: 1700px) {
    .wrap {
        box-sizing: border-box;
    }
    .sideMenu {
        padding: 30px 20px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }
}
@media screen and (max-width: 1550px) {

}


@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .wrap {
        max-width: 1200px;
        padding-left: 0;
    }

    .navigationContainer {
        /*max-width: 1000px;*/
    }
    .navigationContainer .logoSmall img {
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }

    .sideMenu {
        display: none;
    }

    .navigation {
        display: block;
    }

    .footerContainer .footerContentBox .footerTitle span {
        font-size: 14px;
        letter-spacing: -1px;
    }

}
/*@media screen and (max-width: 1250px) {*/
/*    .wrap {*/
/*        width: 1000px;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .wrap {
        max-width: 950px;
        overflow: hidden;
        margin-bottom: 100px;
        padding: 0 30px;
        box-sizing: border-box;
    }

    .sideMenu {
        display: none;
    }

    .footerContainer .footerContentBox {
        padding: 0 30px;
        box-sizing: border-box;
    }

    .footerContainer .footerContentBox .tabletFooter {
        display: block;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*justify-content: space-between;*/
    }

    .footerContainer .footerContentBox .tabletFooter .footerLeft {
        display: inline-block;
        vertical-align: top;
        margin-right: 100px;
    }

    .footerContainer .footerContentBox .tabletFooter .footerRight {
        width: calc(100% - 286px);
        display: inline-block;
        vertical-align: top;
    }

    .footerContainer .footerContentBox .tabletFooter .footerRight .snsLink {
        margin-bottom: 50px;
    }
    .footerContainer .footerContentBox .tabletFooter .footerRight .footerTextContainer{
        display: flex;
        flex-direction: row;
        /*justify-content: space-between;*/
    }
    .footerContainer .footerContentBox .tabletFooter .footerRight .footerTextContainer .privacyPolicy{
        margin-right: 50px;
    }

    .footerContainer .footerContentBox .tabletFooter .footerTitle {
        margin-bottom: 10px;
    }

    .footerContainer .footerContentBox .defaultFooter {
        display: none;
    }
}
@media screen and (max-width: 950px) {
    .wrap {
        max-width: 900px;
    }
}
@media screen and (max-width: 900px) {
    .wrap {
        max-width: 850px;
    }
}
@media screen and (max-width: 850px) {
    .wrap {
        max-width: 800px;
    }
}
@media screen and (max-width: 800px) {
    .wrap {
        max-width: 769px;
    }
}
@media screen and (max-width: 768px) {
    .wrap {
        max-width: 720px;
    }
}
@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .wrap {
        /*max-width: 360px;*/
        overflow: hidden;
        margin-bottom: 50px;
        padding: 0;
        /*padding: 0 30px;*/
        box-sizing: border-box;
    }

    .sideMenu {
        display: none;
    }

    .footerContainer {
        background: rgba(55, 142, 241, 0.06);
        overflow: hidden;
        /*padding: 40px 30px;*/
        padding: 40px;
        box-sizing: border-box;
    }
    .footerContainer .footerContentBox {
        max-width: 768px;
    }


    .footerContainer .footerContentBox {
        display: block;
        text-align: center;
        /*max-width: 360px;*/
        padding: 0;
    }

    .footerContainer .footerContentBox .tabletFooter {
        display: none;
    }

    .footerContainer .footerContentBox .defaultFooter {
        display: block;
    }

    .footerContainer .footerContentBox .whitePaper {
        display: flex;
        flex-direction: row;
        align-items: center;
        /*justify-content: flex-end;*/
        margin-bottom: 30px;
        justify-content: space-between;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown{
        padding: 10px 0;
        width: 162px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span{
        font-size: 14px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 80px;
        height: 30px;
        /*margin: 0 auto;*/
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }

    /*.footerContainer .footerContentBox .whitePaper .whitePaperDown {*/
    /*    padding: 10px 0;*/
    /*    width: 218px;*/
    /*}*/

    /*.footerContainer .footerContentBox .whitePaper .whitePaperDown span {*/
    /*    font-size: 14px;*/
    /*}*/


    .footerContainer .footerContentBox .whitePaper .labelLogo {
        height: 30px;
        /*margin: 0 auto;*/
        margin-bottom: 10px;
        padding-right: 50px;
    }

    .footerContainer .footerContentBox .footerTitle {
        margin-bottom: 10px;
    }

    .footerContainer .footerContentBox .privacyPolicy, .footerContainer .footerContentBox .contactUs {
        margin-bottom: 30px;
        text-align: left;
    }

    .footerContainer .footerContentBox .snsLink {
        text-align: left
    }

    .footerContainer .footerContentBox .snsLink .sns {
        width: 30px;
        height: 30px;
        margin-right: 16px;
    }

    .navigation {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 15px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 120px;
        height: 50px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 23px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 21px;
    }
}
@media screen and (max-width: 720px) {
    .wrap {
        max-width: 670px;
    }
}
@media screen and (max-width: 720px) {
    .wrap {
        max-width: 620px;
    }
}
@media screen and (max-width: 620px) {
    .wrap {
        max-width: 570px;
    }
}

@media screen and (max-width: 560px) {
    .footerContainer .footerContentBox .footerTitle {
        font-size: 18px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 17px;
    }
}

@media screen and (max-width: 425px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 14px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 35px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 14px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 14px;
    }
}

@media screen and (max-width: 375px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown {
        padding: 10px 0;
        width: 160px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 13px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 25px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 13.5px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 13.5px;
    }
}

@media screen and (max-width: 320px) {
    .footerContainer .footerContentBox .whitePaper .whitePaperDown {
        padding: 10px 0;
        width: 190px;
    }
    .footerContainer .footerContentBox .whitePaper .whitePaperDown span {
        font-size: 12px;
    }
    .footerContainer .footerContentBox .whitePaper .labelLogo {
        width: 95px;
        height: 15px;
    }
    .footerContainer .footerContentBox .footerTitle {
        font-size: 12px;
    }
    .footerContainer .footerContentBox .content {
        font-size: 12px;
    }
}

.mainBannerContainer {
    width: 100%;
    height: 825px;
    background-image: url(/static/media/MainBannerBG.916d8bda.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160px;
    padding-left: 300px;
    box-sizing: border-box;
    margin-bottom: 85px;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.mainVideoBannerContainer {
    width: 100%;
    height: 825px;
    margin-bottom: 85px;
    position: relative;
}

.mainVideoBannerContainer .bgContent {
    position: absolute;
    top: 160px;
    right: 300px;
}

/*.mainVideoBannerContainer .bgContent{*/
/*    padding-top: 160px;*/
/*    padding-left:300px;*/
/*    box-sizing: border-box;*/
/*}*/
.mainVideoBannerContainer video {
    min-width: 100%;
    min-height: 100%;

}


.mainVideoBannerContainer .title, .mainBannerContainer .title {
    font-weight: bold;
    font-size: 54px;
    line-height: 81px;
    text-align: center;
    color: #fff;
    display: block;
    margin-bottom: 21px;
    white-space: pre-wrap;

}

.mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    display: block;
    color: #fff;
    margin-bottom: 56px;
    white-space: pre-wrap;

}

.mainVideoBannerContainer .createButton, .mainBannerContainer .createButton {
    display: block;
    background-color: inherit;
    background-image: url(/static/media/CreateNFTbutton.625ee909.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 240px;
    height: 51px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
}

.title.mobile {
    display: none;
}
.title {
    white-space: pre-wrap;
}


@media screen and (max-width: 1550px) {
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .mainBannerContainer {
        padding-left: 0;
        /*background-image: url("assets/images/MainBannerBG_mobile.png");*/
        /*background-position: center center;*/
    }
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 45px;
        line-height: 60px;
        padding: 0 30px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 24px;
        font-weight: 400;
        /*margin-bottom: 30px;*/
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    .mainBannerContainer {
        background-position: center center;
        margin-bottom: 0;
    }
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 40px;
        line-height: 60px;
        padding: 0 30px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 24px;
        font-weight: 400;
        /*margin-bottom: 30px;*/
    }
}

@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .mainBannerContainer {
        padding-top: 120px;
        height: 615px;
        margin-bottom: 0;
        background-position: center;
        /*background-size: contain;*/
        background-image: url(/static/media/MainBannerBG_mobile.9e1b2f90.png);
    }

    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 40px;
        line-height: 48px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .title.desktop {
        display: none;
    }

    .title.mobile {
        display: block;
    }
}
@media screen and (max-width: 560px) {
    .mainBannerContainer .title {
        font-size: 28px;
        line-height: 40px;
    }
    .mainBannerContainer .subTitle {
        font-size: 19px;
        line-height: 30px;
    }
}

@media screen and (max-width: 425px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 26px;
        line-height: 35px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 375px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 24px;
        line-height: 33px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 20px;
        line-height: 30px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 13px;
    }
}


.valueationTitleContainer {
    margin-bottom: 100px;
    text-align: center;
}

.valueationTitleContainer .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;

}

.valueationTitleContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    white-space: pre-wrap;
}
.valueationDesktop{
    display: block;
}
.valueationMobile{
    display: none;
}

.valueationTitleContainer.desktop {
    display: block;
}
.valueationTitleContainer.mobile {
    display: none;
}




.valueationContainer .itemBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 165px;
}

.valueationContainer .itemBox:last-child {
    margin-bottom: 0;
}

.valueationContainer .itemBox .video  {
    width: 654px;
    height: 374px;
    border-radius: 32px;
    -webkit-filter: drop-shadow(0px 8px 30px rgba(55, 142, 241, 0.16));
            filter: drop-shadow(0px 8px 30px rgba(55, 142, 241, 0.16));
}

.valueationContainer .itemBox .video video {
    width: 100%;
    height: 100%;
}

.valueationContainer .itemBox .contentBox.reverse .title, .valueationContainer .itemBox .contentBox.reverse .subTitle {
    text-align: left;
}

.valueationContainer .itemBox .contentBox .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    text-align: right;
    margin-bottom: 12px;
    display: block;
    white-space: pre-wrap;
    /*word-break: break-all*/
}

.valueationContainer .itemBox .contentBox .subTitle {
    text-align: right;
    font-size: 18px;
    line-height: 27px;
    display: block;
    white-space: pre-wrap;
    /*word-break: break-all*/
}

@media screen and (max-width: 1550px) {
    .valueationContainer .itemBox .video {
        width: 570px;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .valueationContainer {
        padding: 0 50px;
    }


    .valueationContainer .itemBox .video {
        width: 500px;
    }
    .valueationContainer .itemBox .contentBox .title{
        font-size: 26px;
        line-height: 32px;
        white-space: pre-wrap;

    }
    .valueationContainer .itemBox .contentBox .subTitle{
        font-size: 18px;
        line-height: 26px;
        white-space: pre-wrap;
    }
}

@media screen and (max-width: 1250px) {
    .valueationDesktop {
        /*padding: 0 30px;*/
    }
    .valueationContainer .itemBox .contentBox .title {
        font-size: 24px;
        line-height: 32px;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 16px;
        line-height: 26px;
        white-space: pre-wrap;
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .valueationContainer{
        margin-top: -100px;
    }
    .valueationContainer .itemBox{
        margin-bottom: 0;
    }
    .valueationContainer .itemBox .video {
        width: 350px;
        height: 355px;
        -webkit-filter: drop-shadow(0px 8px 15px rgba(55, 142, 241, 0.1));
                filter: drop-shadow(0px 8px 15px rgba(55, 142, 241, 0.1));
    }

    .valueationContainer .itemBox .contentBox .title{
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 0;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle{
        font-size: 15px;
        line-height: 24px;
        letter-spacing:-1px;
        white-space: pre-wrap;
    }
}

@media screen and (max-width: 768px) {
    .valueationTitleContainer.desktop {
        display: none;
    }
    .valueationTitleContainer.mobile {
        display: block;
    }
    .valueationDesktop{
        display: none;
    }
    .valueationMobile{
        display: block;
    }
    .valueationTitleContainer .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        white-space: pre-wrap;

    }
    .valueationTitleContainer .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        white-space: pre-wrap;
    }
    .valueationTitleContainer span {
        padding: 0 30px;
    }
    /* 모바일 가로, 타블렛 세로 */
    .title {
        font-size: 18px;
    }

    .valueationContainer {
        margin-top: -60px;
        padding: 0 30px
    }

    .valueationContainer .itemBox {
        display: block;
        margin-bottom: 40px;
    }

    .valueationContainer .itemBox .contentBox .title {
        font-size: 22px;
        line-height: 32px;
        white-space: pre-wrap;

    }

    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 16px;
        line-height: inherit;
        white-space: pre-wrap;
    }

    .valueationContainer .itemBox .contentBox.reverse .title, .valueationContainer .itemBox .contentBox.reverse .subTitle {
        text-align: center;
        /*white-space: pre;
        */
        /*padding: 0 13px;*/
        word-break: keep-all;
        /*white-space: revert;*/
        margin-bottom: 25px;
    }

    .valueationContainer .itemBox .video {
        /*width: 300px;*/
        width: 100%;
        height: 245px;
        margin: 0 auto;
        -webkit-filter: inherit;
                filter: inherit;
    }

    .valueationContainer .itemBox .contentBox.reverse .subTitle {
        line-height: 25px;
    }

}


@media screen and (max-width: 320px) {
    .valueationContainer .itemBox .contentBox .title {
        font-size: 19px;
        white-space: pre-wrap;
    }
    .valueationContainer .itemBox .contentBox .subTitle {
        font-size: 14px;
        white-space: pre-wrap;
    }
}
.bannerContainer {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEFSURBVHgB7ZRBDsFQEIZnFCUsuifhCI7ADThBewQSewcQwQnKEZxAj+AIXVp2I1VJ33hv0RKJmGltJP5Fm0n7vu8lM+8B/HpQ8nN3GQ/JsnoXrB2iGUacNRUQRFngE6W7JiVHZ00OZ41IQACheSPRgCsRCWxsTADxJJGIemDS18CbBoMWmJq0MEZ79K4nYoFUUkggkRQWcCW5wDSrTYmriDd+DwA6BDTN6ldJNfvQUtexAtiAMGSG91lopkslrqZvTZ2PaaogzOa8bFKsBLkQSqazij29MT+rFVneeV7ff0XwCV5KwIEXFnDhhQQSuFgghZvIrmuEhQQuFugzFehHxIX/w8odl4a0a9kSUiwAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    padding-left: 300px;
    text-align: center;
    margin-bottom: 200px;
}

.bannerContainer span {
    display: block;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: #fff;
    margin-bottom: 50px;
}

@media screen and (max-width: 1550px) {

}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .bannerContainer {
        padding-left: 0;
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    .bannerContainer {
        margin-bottom: 100px;
        margin-top: -50px;
    }
}



@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .bannerContainer {
        margin-bottom: 100px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEFSURBVHgB7ZRBDsFQEIZnFCUsuifhCI7ADThBewQSewcQwQnKEZxAj+AIXVp2I1VJ33hv0RKJmGltJP5Fm0n7vu8lM+8B/HpQ8nN3GQ/JsnoXrB2iGUacNRUQRFngE6W7JiVHZ00OZ41IQACheSPRgCsRCWxsTADxJJGIemDS18CbBoMWmJq0MEZ79K4nYoFUUkggkRQWcCW5wDSrTYmriDd+DwA6BDTN6ldJNfvQUtexAtiAMGSG91lopkslrqZvTZ2PaaogzOa8bFKsBLkQSqazij29MT+rFVneeV7ff0XwCV5KwIEXFnDhhQQSuFgghZvIrmuEhQQuFugzFehHxIX/w8odl4a0a9kSUiwAAAAASUVORK5CYII=);
        background-size: cover;
        padding: 64px 0;
    }

    .bannerContainer span {
        font-size: 22px;
        margin-bottom: 22px;
        line-height: inherit;
    }

}


/*.bannerContainer button{*/
/*    width: 240px;*/
/*    height: 51px;*/
/*    color:#fff;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: block;*/
/*    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);*/
/*    box-shadow: 0px 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);*/
/*    border-radius: 26px;*/
/*    border: none;*/
/*    margin: 0 auto;*/

/*}*/

/*.keypartnersTitle {*/
/*    font-weight: 700;*/
/*    font-size: 38px;*/
/*    line-height: 46px;*/
/*    text-align: center;*/
/*    margin-bottom: 100px;*/
/*    padding-left: 225px;*/
/*}*/
.partnersContainer .itemBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 165px;
}

.partnersContainer .itemBox:last-child {
    margin-bottom: 0;
}

.partnersContainer .itemBox:nth-child(2) .contentBox {
    padding-top: 20px;
}
.partnersContainer .itemBox:nth-child(3) .contentBox {
    padding-top: 25px;
}
.partnersContainer .itemBox:nth-child(4) .contentBox {
    padding-top: 28px;
}
.partnersContainer .itemBox:nth-child(5) .partnerImage {
    padding-top: 20px;
}

.partnersContainer .itemBox .partnerImage{
    width: 805px;
    border-radius: 32px;
    /*filter: drop-shadow(0px 8px 30px rgba(55, 142, 241, 0.16));*/
    text-align: center;
    /*padding-top: 30px;*/
}

.partnersContainer .itemBox .partnerImage .imageTitle {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: block;
    padding-top: 25px;
    margin-bottom: 40px;
}

.partnersContainer .itemBox .contentBox .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 60px;
    display: block;
    text-align: start;
}

.partnersContainer .itemBox .contentBox .companyName {
    font-weight: bold;
    display: block;
    font-size: 20px;
    margin-bottom: 20px;
}

.partnersContainer .itemBox .contentBox .contentText {
    font-size: 15px;
    line-height: 24px;
    display: block;
    white-space: pre-wrap;
    word-break: break-all
}

.partnersContainer.mobile {
    display: none;
}
.partnersContainer.tablet {
    display: none;
}

@media screen and (max-width: 1800px) {
    .partnersContainer {
        padding-left: 350px;
    }
    .partnersContainer .itemBox .partnerImage {
        width: 670px;
        height: auto;
    }
}

@media screen and (max-width: 1700px) {
    .partnersContainer .itemBox .contentBox .contentText{
        font-size: 14px;
    }
    .partnersContainer .itemBox .partnerImage {
        width: 630px;
        height: auto;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
        /*padding-left: 130px;*/
    }

    .partnersContainer.tablet {
        /*max-width: 1200px;*/
        padding: 0 50px;

    }

    .partnersContainer .itemBox .partnerImage{
        width: 550px;
    }
    .partnersContainer .itemBox .contentBox .title{
        font-size: 26px;
        margin-bottom: 30px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 18px;
        line-height: 70px;
        margin-bottom: 0;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 16px;
        line-height: 26px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 1250px) {
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
    }

    .partnersContainer {
        padding: 0 50px;
        /*max-width: 1000px;*/
    }
    .partnersContainer .itemBox .contentBox .title {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 16px;
        margin-bottom: 0;
    }
    .partnersContainer .itemBox .contentBox .contentText{
        font-size: 13px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle{
        font-size: 18px;
    }
    .partnersContainer .itemBox .partnerImage{
        width: 400px;
    }
}

@media screen and (max-width: 1000px) {
    /* 모바일 가로, 타블렛 세로 */
    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.mobile {
        display: none;
    }
    .partnersContainer.tablet {
        display: block;
    }

    .partnersContainer .itemBox .contentBox .title {
        font-size: 24px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 19px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 15px;
        letter-spacing: -1px;
        width: 400px;
    }

}

@media screen and (max-width: 768px) {
    /*.keypartnersTitle {*/
    /*    text-align: left;*/
    /*    font-weight: 700;*/
    /*    font-size: 24px;*/
    /*    margin-bottom: 32px;*/
    /*    padding-left: 40px;*/
    /*}*/
    .partnersContainer {
        padding: 0 30px;
    }
    .partnersContainer .itemBox {
        display: block;
        text-align: center;
        margin-bottom: 100px;
    }
    .partnersContainer .itemBox .partnerImage {
        margin: 0 auto;
        width: 100%;
    }
    .partnersContainer .itemBox .contentBox .title{
        line-height: inherit;
        margin-bottom: 20px;
        text-align: center;
        font-size: 34px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 70px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        word-break: keep-all;
        white-space: pre-wrap;
        margin-bottom: 25px;
        font-size: 24px;
        line-height: 36px;
        padding: 0 1px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 28px;
        line-height: 70px;
        margin-bottom: 17px;
    }

    .partnersContainer.desktop {
        display: none;
    }
    .partnersContainer.tablet {
        display: none;
    }
    .partnersContainer.mobile {
        display: block;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 767px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}

@media screen and (max-width: 700px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}

@media screen and (max-width: 600px) {
    .partnersContainer .itemBox .contentBox .contentText {

        padding: 0 30px;

        width: auto;
    }
}
@media screen and (max-width: 560px) {
    .partnersContainer .itemBox .contentBox .title {
        font-size: 24px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 20px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: -1.5px;
        padding: 0px 1px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 20px;
        line-height: 26px;
    }
}


@media screen and (max-width: 425px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 21px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 18px;
        line-height: 32px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 16px;
        line-height: 28px;
        padding: 0 1px;
        margin-bottom: 0px;
        letter-spacing: 0px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 375px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 20px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 17px;
        line-height: 31px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 14px;
        line-height: 22px;
        padding: 0 1px;
        margin-bottom: 0px;
        width: auto;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 17px;
        line-height: 26px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 320px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 19px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 16px;
        line-height: 30px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 12.9px;
        line-height: 20px;
        padding: 0 1px;
        margin-bottom: 0px;
        width: auto;
        letter-spacing: -1px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
}

@media screen and (max-width: 319px) {
    .partnersContainer .itemBox .contentBox .title{
        font-size: 15px;
    }
    .partnersContainer .itemBox .contentBox .companyName {
        font-size: 13px;
        line-height: 20px;
    }
    .partnersContainer .itemBox .contentBox .contentText {
        font-size: 11px;
        line-height: 20px;
    }
    .partnersContainer .itemBox .partnerImage .imageTitle {
        font-size: 13px;
        line-height: 20px;
    }
}

.contactContainer .title {
    text-align: start;
}


.contactContainer {
    padding-bottom: 100px;
}

.contactContainer .formContainer {
    padding: 50px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
    text-align: right;
}

.contactContainer .formContainer .inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    text-align: left;
}

.contactContainer .formContainer .inputContainer .error {
    color: red;
}

.contactContainer .formContainer .left {
    width: 570px
}

.contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
}

.contactContainer .formContainer .left input {
    width: 100%;
    height: 62px;
    /*padding: 16px;*/
    box-sizing: border-box;
    padding-left: 64px;
    border: 2px solid #F1F1F1;
    border-radius: 13px;
    color: #707070;
    font-size: 16px;
}

.contactContainer .formContainer .left input:focus,.contactContainer .formContainer .right textarea:focus {
    outline: 1.3px solid #1A8DF7;

    outline-width: 0;
    border: 1.3px solid #1A8DF7;
    border-radius: 13px;

    caret-color:#1A8DF7;
}

.contactContainer .formContainer .left img {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.contactContainer .formContainer .right textarea {
    width: 605px;
    height: 208px;
    border: 2px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 13px;
    padding: 20px;
    resize: none;
    font-size: 16px;
    color: #707070;
}

.right .error {
    padding-left: 27px;
}

.contactContainer .formContainer .sendbtn {
    padding: 15px 54px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    border: none;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
    border-radius: 26px;
}
@media screen and (max-width: 1800px) {
    .contactContainer{
        padding-left: 350px;
    }
}


@media screen and (min-width: 1551px) {
    .contactContainer .formContainer .right .label{
        margin-left: 20px;
    }
    .contactContainer .formContainer .right textarea{
        width: 500px;
        margin-left: 20px;
    }
}


@media screen and (max-width: 1550px) {
    .contactContainer .formContainer .right .label {
        margin-left: 20px;
    }

    .contactContainer .formContainer .right textarea {
        width: 500px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 1450px) {
    .contactContainer {
        padding: 0 50px;
        grid-gap: 50px;
        gap: 50px;
    }
    .contactContainer .formContainer .left input {
        width: 90%;
    }
    .form_group {
        height: 150px;
    }


}
@media screen and (max-width: 1250px) {
    .contactContainer {
        /*padding: 0 30px;*/
        padding-bottom: 20px;
    }
    .contactContainer .formContainer .right textarea {
        width: 450px;
    }
}

    /*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1024px) {
    .contactContainer .formContainer {
        padding: 30px;
        box-shadow: 0 8px 15px rgb(55 142 241 / 10%);
    }
    .contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label{
        font-size: 18px;
    }

    .contactContainer .formContainer .right .label{
        margin-left: 0;
    }

    .contactContainer .formContainer .inputContainer {
        padding: 0;
    }

    .contactContainer .formContainer .left {
        width: calc(50% - 20px);
    }

    .contactContainer .formContainer .right {
        width: calc(50% - 20px);

    }
    .contactContainer .formContainer .left input{
        font-size: 15px;
    }
    .contactContainer .formContainer .right textarea {
        width: 100%;
        margin-left: 0;
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .contactContainer .title {
        margin-left: 0px;
    }
    .contactContainer {
        padding: 0 30px 40px;
    }

    .contactContainer .formContainer {
        padding: 0;
        box-shadow: inherit;
        border-radius: inherit;
    }

    .contactContainer .formContainer .inputContainer {
        display: block;
        padding: 0;
        margin-bottom: 20px;

    }

    .contactContainer .formContainer .left {
        width: inherit;
    }

    .contactContainer .formContainer .left .label, .contactContainer .formContainer .right .label {
        margin-left: 0;
        font-size: 14px;

    }

    .contactContainer .formContainer .left img {
        width: 24px;
        height: 24px;
    }

    .contactContainer .formContainer .left input {
        width: 100%;
        font-size: 14px;
        padding-left: 50px;
    }

    .contactContainer .formContainer .right {
        width: 100%
    }

    .contactContainer .formContainer .right textarea {
        width: 100%;
        margin-left: 0;
        font-size: 14px;
    }

    .contactContainer .formContainer .form_group {
        margin-bottom: 25px;
    }

    .form_group {
        height: 80px;
    }
}

@media screen and (max-width: 425px) {
    .contactContainer {
        max-width: 425px;
    }
    .title {
        font-size: 26px;
    }
}
@media screen and (max-width: 375px) {
    .contactContainer {
        max-width: 375px;
    }
    .title {
        font-size: 26px;
    }
}
@media screen and (max-width: 320px) {
    .contactContainer {
        max-width: 320px;
        margin: 0 auto;
    }
    .title {
        font-size: 24px;
    }
}
.roadmapContainer{
    margin-bottom: 198px;
}

.roadmapImageMobile{
    display: none;
}

.roadmapContainer .roadmap{
    /*background-image: url("assets/images/roadmapBar.png");*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    /*width: 100%;*/
    /*height: 3px;*/
}

.roadmapContainer .title{
    /*max-width: 1420px;*/
    width: 100%;
    margin: 0 auto;
    margin-bottom: 100px;
    /*padding-left: 150px;*/
    box-sizing: border-box;
    text-align: start;
    margin-top: 150px;
}


/*.roadmapContainer img {*/
/*    width: 1420px;*/
/*    height: auto;*/
/*    !*padding-left: 390px;*!*/
/*}*/
@media screen and (max-width: 1800px) {
    .roadmapContainer {
        padding-left: 350px;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
    }
}

@media screen and (max-width: 1700px) {
    .roadmapContainer {
        max-width: 1550px;
    }
}

@media screen and (max-width: 1550px){
    /*.roadmapContainer .title{*/
    /*    padding-left: 300px;*/
    /*}*/
}
@media screen and (max-width:1450px){
    .roadmapContainer {
        padding: 0 50px;
        max-width: 1200px;
        margin-bottom: 150px;
    }
    .roadmapContainer .title {
        margin-bottom: 50px;
        margin-top: 135px;
    }
}
@media screen and (max-width: 1250px) {
    .roadmapContainer .title{
        margin-bottom: 50px;
    }
}

/*@media screen and (max-width: 860px){*/
@media screen and (max-width: 1000px){
    .roadmapContainer{
        margin-bottom: 100px;
        max-width: 900px;
    }
    .roadmapContainer .title{
        /*padding-left: 0px;*/
        margin-bottom: 50px;
    }
    .roadmapPC{
        display: none;
    }
    .roadMapTablet{
        display: block;
    }
    .roadMapMobile{
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .roadmapContainer{
        max-width: 850px;
    }
}
@media screen and (max-width: 800px) {
    .roadmapContainer{
        max-width: 750px;
    }
}

@media screen and (max-width:768px){
    .roadmapContainer {
        max-width: 768px;
        padding: 0;
    }

    .roadmapImageDesktop{display: none}
    .roadmapImageMobile{
        display: block;
    }
    .roadmapContainer {
        margin-bottom: 150px;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 30px;
        margin: 60px 0 0;
        font-size: 30px;
    }
    .roadmapImage {
        margin: 0 auto;
    }
    .roadmapPC{
        display: none;
    }
    .roadMapTablet{
        display: none;
    }
    .roadMapMobile{
        display: block;
    }
}
@media screen and (max-width:425px) {
    .roadmapContainer {
        max-width: 425px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 20px;
    }
}
@media screen and (max-width:375px) {
    .roadmapContainer {
        max-width: 375px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 19px
    }

}
@media screen and (max-width:320px) {
    .roadmapContainer {
        max-width: 320px;
        padding: 0;
    }
    .roadmapContainer .title {
        padding: 0 0 20px 20px;
        font-size: 19px
    }

}


/* position */
.p_relative {
    position: relative;
}

/* margin */
.mb_50 {
    margin-bottom: 50px;
}

/* title */
.title {
    display: block;
    font-weight: bold;
    font-size: 38px;
    margin-bottom: 50px;
}

/* profileItem */
.teamAContainer .title {
    text-align: start;
}

.teamAContainer .profileBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
}

.teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
    height: 288px;
    padding: 30px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(55, 142, 241, 0.16);
    border-radius: 26px;
}


.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*align-items: center;*/
}
.teamAContainer .profileBox .profile .nameImage, .adviserContainer .profileBox .profile .nameImage span {
    bottom: 20px;
}

.teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
}

.teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
    width: 80px;
    height: 80px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox .profile .nameImage .profileImage img, .adviserContainer .profileBox .profile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox .profile .nameImage .position, .adviserContainer .profileBox .profile .nameImage .position {
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: #1A8DF7;
    padding-top: 5px;
}

.teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
    font-size: 15px;
    line-height: 24px;
    white-space: pre-wrap;
    word-break: break-all;
}

/* profileItem 모바일 */
.teamAContainer .profileBox_mobile .profile_group, .adviserContainer .profileBox_mobile .profile_group {
    width: 100%;
    /*height: 328px;*/
    padding-bottom: 20px;
    box-sizing: border-box;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile, .adviserContainer .profileBox_mobile .profile_group .profile_mobile {
    width: calc(100% - 23px);
    height: 180px;
    margin: 0 auto;
    margin-bottom: 23px;
    background: #FFFFFF;
    /* picture shadow */
    box-shadow: 0 8px 30px rgba(55, 142, 241, 0.1);
    border-radius: 26px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0), .adviserContainer .profileBox_mobile .profile_group .profile_mobile:nth-child(4n+0) {
    margin-bottom: 0;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage {
    /*width: 100%;*/
    width: calc(340px - 90px);
    height: 84px;
    background-color: #fff;

}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    width: 70px;
    height: 70px;
    /*border: 2px solid rgba(255, 255, 255, 0.3);*/
    border: 2px solid rgba(90, 90, 90, 0.3);
    border-radius: 50%;
    overflow: hidden;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .namePosition {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 4px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img, .adviserContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .profileImage img {
    background-color: #C9C9C9;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .name, .profileBox_mobile .profile_group .profile_mobile .nameImage .name {
    font-weight: bold;
    font-size: 17px;
    display: block;
    margin-bottom: 10px;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .nameImage .position, .profileBox_mobile .profile_group .profile_mobile .nameImage .position {
    font-weight: bold;
    font-size: 14px;
    color: #1A8DF7;
    display: block;
}

.teamAContainer .profileBox_mobile .profile_group .profile_mobile .profileHistory, .profileBox_mobile .profile_group .profile_mobile .profileHistory {
    width: calc(340px - 80px);
    /*min-height: 82px;*/
    background-color: #fff;
    text-align: center;
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    line-height: 19px;
    letter-spacing: -1px;
}

.teamContentsMobile {
    display: none;
}

/*.teamAContainer {*/
/*    max-width: 1630px;*/
/*}*/

/* Create NFT button */
.CreateNFT {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

.CreateNFTcontainer {
    width: 240px;
    height: 55px;
    margin: 0 auto;
}

/*.CreateNFT_shadow{*/
/*    width: 240px;*/
/*    height: 51px;*/
/*    color:#fff;*/
/*    font-size: 18px;*/
/*    font-weight: 400;*/
/*    display: block;*/
/*    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);*/
/*    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0 5px 5px rgba(255, 255, 255, 0.3);*/
/*    border-radius: 26px;*/
/*    border: none;*/
/*    margin: 0 auto;*/
/*}*/
.CreateNFT_shadow {
    display: block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.CreateNFTActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.CreateNFTActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.CreateNFTActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* SendMessage */
/*.SendMessage {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #3755F1;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*    position: relative;*/
/*}*/

/*.SendMessage .subBackground {*/
/*    width: 236px;*/
/*    height: 46px;*/
/*    line-height: 46px;*/
/*    background-color: #fff;*/
/*    color: #3755F1;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    border-radius: 26px;*/
/*}*/

/*.SendMessageActive {*/
/*    !*display: block;*!*/
/*    width: 240px;*/
/*    height: 50px;*/
/*    border: none;*/
/*    !*margin: 0 auto;*!*/
/*    cursor: pointer;*/
/*    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));*/
/*    filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));*/
/*    border-radius: 26px;*/
/*    color: #fff;*/
/*    font-weight: 500;*/
/*    font-size: 18px;*/
/*}*/
.SendMessage {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2);
}

/*.SendMessageTcontainer {*/
/*    width: 240px;*/
/*    height: 55px;*/
/*    margin: 0 auto;*/
/*}*/

.SendMessage_shadow {
    display: inline-block;
    background-color: inherit;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    width: 240px;
    height: 50px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 26px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;

}

.SendMessageActive {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow {
    /*display: block;*/
    width: 240px;
    height: 50px;
    border: none;
    /*margin: 0 auto;*/
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 194, 231, 1), rgba(99, 0, 249, 1));
    -webkit-filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
            filter: drop-shadow(0px 5px 12px rgba(55, 85, 241, 0.2));
    border-radius: 26px;
    color: #3755F1;
    font-weight: 500;
    font-size: 18px;
    position: relative;
}

.SendMessageActive_shadow .subBackground {
    width: 236px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    color: #3755F1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 26px;
}

.SendMessageActive_shadow .subBackground span {
    background: linear-gradient(to left, rgba(99, 0, 249, 1), rgba(0, 194, 231, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 18px;
}

/* White Paper */
.whitePaperDown {
    background: rgba(36, 124, 238, 0.1);
    border-radius: 23px;
    border: none;
    padding: 10px 24px;
    cursor: pointer;
}

.whitePaperDown .whitePaperTitle {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: #1A8DF7;
    font-weight: bold;
    font-size: 15px;
}

.whitePaperDown img {
    display: inline-block;
    vertical-align: middle;
    width: 17.5px;
    height: 18px;
}

.whitePaperDown:hover img {
    position: relative;
    -webkit-animation: slideArrow 1.1s linear infinite;
            animation: slideArrow 1.1s linear infinite;
}

.whitePaperDown.sideMenuWhitePaper {
    background-color: inherit;
    border-radius: inherit;
    margin-bottom: 30px;
    padding: 0;
}
@media screen and (max-width: 1800px) {
    .teamAContainer {
        padding-left: 350px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
    }
}


@media screen and (min-width: 1551px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1550px) {
    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
    }
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .wrap {
        max-width: 1250px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 30px;
    }

    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer {
        padding: 0 50px;
    }



}
@media screen and (max-width: 1250px) {
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 11.9px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 15px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 14px;
        letter-spacing: -1px;
    }
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 50px;
        grid-gap: 30px;
        gap: 30px;
    }
}


/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1024px) {
    .teamAContainer {
        margin-top: 150px;
        padding: 0 50px;
    }

    .teamAContainer .title {
        font-size: 24px;
        /*margin-left: 25px;*/
    }

    .teamAContainer .profileBox .profile, .adviserContainer .profileBox .profile {
        height: auto;
        padding: 20px 30px;
        box-shadow: 0 8px 15px rgb(55 142 241 / 10%);
    }

    .teamAContainer .profileBox .profile .profileHistory, .adviserContainer .profileBox .profile .profileHistory {
        font-size: 12px;
        letter-spacing: -1px;
    }

    .teamAContainer .profileBox .profile .nameImage .name, .adviserContainer .profileBox .profile .nameImage .name {
        font-size: 18px;
    }

    .teamAContainer .profileBox .profile .nameImage .profileImage, .adviserContainer .profileBox .profile .nameImage .profileImage {
        width: 70px;
        height: 70px;
    }

    .teamAContainer .profileBox {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        gap: 20px;
    }

    .SendMessage {
        -webkit-filter: drop-shadow(0px 8px 15px rgba(55, 85, 241, 0.1));
                filter: drop-shadow(0px 8px 15px rgba(55, 85, 241, 0.1));
    }

    .whitePaperDown {
        width: 186px;
        height: auto;
    }
    .footerContainer .footerContentBox .snsLink .sns {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }

    .teamContentsDesktop {
        padding-bottom: 50px;
    }
}
@media screen and (max-width: 999px) {
    .teamAContainer .profileBox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
        grid-gap: 30px;
        gap: 30px;
    }
    .teamAContainer .profileBox .profile .profileHistory {
        font-size: 13px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */

    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }

    .teamAContainer {
        padding: 0 30px;
    }
    .teamContentsDesktop {
        display: none;
    }
    .teamContentsMobile {
        display: block;
    }

    .SendMessageActive {
        width: 150px;
        height: 35px;
        font-size: 14px;
        color: #FFFFFF;
    }

    .SendMessage .subBackground {
        width: 146px;
        height: 32px;
        line-height: 31px;
        font-size: 14px;
    }

    .SendMessage {
        width: 150px;
        height: 35px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 23px;
    }

    .CreateNFT_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow {
        width: 189px;
        height: 44px;
    }

    .CreateNFTActive_shadow .subBackground {
        width: 185px;
        height: 40px;
        line-height: 40px;
    }

}
@media screen and (max-width: 560px) {
    .teamAContainer .title {
        margin: 0;
        padding-bottom: 20px;
    }
}


@media screen and (max-width: 425px) {
    .teamAContainer {
        max-width: 425px;
    }
    .teamAContainer .title {
        font-size: 26px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 23px;
    }
}
@media screen and (max-width: 375px) {
    .teamAContainer {
        max-width: 375px;
    }
    .teamAContainer .title {
        font-size: 24px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 22px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 21px;
    }
}
@media screen and (max-width: 320px) {

    .teamAContainer {
        max-width: 320px;
        padding: 0;
    }
    .teamAContainer .title {
        font-size: 21px;
        padding-left: 10px;
    }
    .teamAContainer .profileBox .profile .nameImage .name {
        font-size: 20px;
    }
    .teamAContainer .profileBox .profile .nameImage .position {
        font-size: 19px;
    }
}
.modalOverlay {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    text-align: center;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1022;

}
.modalOverlay > div {
  //position: relative;
  //margin-top: 200px;
      display: inline-block;
      width: 600px;
      min-height: 200px;
      background: white;
      --saf-0: rgba(var(--sk_foreground_low, 29, 28, 29), 0.13);
      box-shadow: 0 0 0 1px var(--saf-0), 0 4px 12px 0 rgba(0, 0, 0, 0.12);
      background-color: rgba(248, 248, 248, 1);
      background-color: rgba(var(--sk_foreground_min_solid, 248, 248, 248), 1);
      border-radius: 6px;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      max-width: 440px;
      padding: 30px;
      box-sizing: border-box;
      z-index: 1025;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
  }

.storyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.storyContainer.mobile{
    display: none;
}
.storyContainer.tablet {
    display: none;
}

/*.storyContainer:nth-child(2) {*/
/*    padding-left: 50px;*/
/*}*/
.storyContainer .storyContents .redTitle {
    display: block;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    color: #F63657;
    white-space: pre-wrap;

}

.storyContainer .title {
    display: block;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 30px 0;
    white-space: pre-wrap;
}

.storyContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    word-break: keep-all;
    margin: 0 auto;
    margin-bottom: 60px;
    white-space: pre-wrap;
}

.storyContainer .storyImage {
    display: block;
    /*max-width: 600px;*/
    /*max-height: 426px;*/
    text-align: center;
    /*padding-left: 100px;*/
    /*box-sizing: border-box;*/
}

@media screen and (max-width: 1450px){
    .storyContainer.tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .storyContainer.mobile{
        display: none;
    }
    .storyContainer {
        display: none;
    }
    .storyContainer.tablet .title {
        display: block;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        white-space: pre-wrap;
    }
    .storyContainer.tablet .subTitle {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -1.5px;
        text-align: center;
        white-space: pre-wrap;
        margin-bottom: 10px;
    }
    .storyContainer.tablet .storyImage {
        padding: 30px 100px 0;
    }
}

@media screen and (max-width:1250px) {
    /* 타블렛 가로 */
    .storyContainer.tablet .title {
        font-size: 22px;
        line-height: 29px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 15.5px;
        line-height: 27px;
    }
}
@media screen and (max-width:1024px) {
    .storyContainer.tablet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .storyContainer.mobile{
        display: none;
    }
    .storyContainer {
        display: none;
    }
    .storyContainer.tablet .title {
        font-weight: 900;
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        white-space: pre-wrap;
    }
    .storyContainer.tablet .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
        letter-spacing: -1.5px;
        white-space: pre-wrap;
    }

}
@media screen and (max-width:1000px) {
    .storyContainer.tablet .title {
        font-size: 20.5px;
        line-height: 27.5px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 14.5px;
        line-height: 25.5px;
    }
}
@media screen and (max-width:900px) {
    .storyContainer.tablet .title {
        font-size: 20px;
        line-height: 27px;
    }
    .storyContainer.tablet .subTitle {
        font-size: 14px;
        line-height: 25px;
    }
}

@media screen and (max-width:768px) {
    .storyContainer {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*text-align: center;*/
        /*margin-bottom: 50px;*/
        /*padding: 0 16px;*/
        display: none;
    }
    .storyContainer.tablet {
        display: none;
    }

    .storyContainer.mobile{
        display: block;
        padding: 0 30px;
    }
    .storyContents.textContents{
        display: none;
    }

    .storyContainer.mobile .title {
        display: block;
        font-weight: 700;
        font-size: 28px;
        line-height: 45px;
        text-align: center;
        white-space: pre-wrap;
        margin: 30px 0;
    }
    .storyContainer.mobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 19px;
        line-height: 30px;
        text-align: center;
        white-space: pre-wrap;
        margin-bottom: 10px;
    }
    .storyContainer .storyImage {
        /*width: 80%;*/
        /*height: auto;*/
        padding: 33px 0;
    }

    .storyContainer .storyImage img {
        width: 70%;
    }
}

@media screen and (max-width:560px) {
    .storyContainer.mobile .title {
        font-size: 23px;
        line-height: 50px;
    }
    .storyContainer.mobile .subTitle {
        font-size: 18px;
        line-height: 30px;
    }
}

@media screen and (max-width:425px) {
    .storyContainer {
        max-width: 425px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:375px) {
    .storyContainer {
        max-width: 375px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 23px;
        line-height: 33px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
    }
}

@media screen and (max-width:320px) {
    .storyContainer {
        max-width: 320px;
    }
    .storyContainer.mobile .title {
        font-weight: 900;
        font-size: 19px;
        line-height: 30px;
        text-align: center;
    }
    .storyContainer.mobile .subTitle {
        height: auto;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 10px;
    }
}
.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metaverseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metaverseContainer .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;
}
.metaverseContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 60px;
    white-space: pre-wrap;
}
.metaverseContainer .metaverseImg {
    text-align: center;
}
.metaverseContainer .metaverseImg img {
    width: 80%;
}

.metaverseContainer .title.mobile{
    display: none;
}
.metaverseContainer .subTitle.mobile{
    display: none;
}

@media screen and (max-width: 1550px){
    .metaverseContainer {
        padding-left: 350px;
    }
}
@media screen and (max-width: 1450px){
    .metaverseContainer {
        padding: 0px;
    }
    .metaverseContainer .title {
        font-size: 26px;
        line-height: 32px;
    }
    .metaverseContainer .subTitle {
        font-size: 18px;
        line-height: 26px;
    }
    .metaverseContainer .metaverseImg img {
        width: 50%;
    }

}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
}

@media screen and (max-width:1000px) {
    .metaverseContainer .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 32px;
    }
    .metaverseContainer .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 32px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width:768px) {
    .metaverseContainer .metaverseImg img {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    }
    .metaverseContainer .title.mobile {
        display: block;
        font-weight: 700;
        font-size: 34px;
        line-height: 48px;
        text-align: center;
        white-space: pre-wrap;
    }
    .metaverseContainer .subTitle{
        display: none;
    }
    .metaverseContainer .subTitle.mobile {
        display: block;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
        white-space: pre-wrap;

    }
    .metaverseContainer span {
        padding: 0 30px;
    }
    .metaverseImg {
        margin: 0 auto;
    }

    .metaverseContainer .title{
        display: none;
    }
    .metaverseContainer .subTitle{
        display: none;
    }
}
@media screen and (max-width:560px) {
    .metaverseContainer .title.mobile {
        font-size: 28px;
    }
}
@media screen and (max-width:425px) {
    .metaverseContainer{
        max-width: 425px;
    }
    .metaverseContainer .title.mobile {
        font-size: 24px;
        line-height: 34px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 18px;
        line-height: 25px;
    }
}
@media screen and (max-width:375px) {
    .metaverseContainer{
        max-width: 375px;
    }
    .metaverseContainer .title.mobile {
        font-size: 21px;
        line-height: 30px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 16px;
        line-height: 23px;
    }
}

@media screen and (max-width:320px) {
    .metaverseContainer{
        max-width: 320px;
    }
    .metaverseContainer .title.mobile {
        font-size: 16px;
        line-height: 27px;
    }
    .metaverseContainer .subTitle.mobile {
        font-size: 14px;
    }
}

.valueationTitleContainer {
    margin-top: 130px;
    margin-bottom: 60px;
}

.valueationTitleContainer .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
}

.valueationTitleContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}

@media screen and (max-width: 1450px){
    .valueationTitleContainer .subTitle {
        letter-spacing: -1px;
    }
}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
}
@media screen and (max-width:1000px) {
    .valueationTitleContainer {
        margin-top: 200px;
        margin-bottom: 0px;
    }

    .valueationTitleContainer .title {
        font-size: 30px;
        line-height: 40px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 18.5px;
        line-height: 32px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width:768px) {
    .valueationTitleContainer {
        margin-top: 120px;
        margin-bottom: 50px;
    }


    .valueationTitleContainer .title {
        font-weight: 700;
        font-size: 34px;
        line-height: 48px;
        text-align: center;
        color: #1A8DF7;
    }
    .valueationTitleContainer .subTitle {
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
    }
    .valueationTitleContainer span {
        padding: 0 30px;
    }
}

@media screen and (max-width:560px) {
    .valueationTitleContainer .title {
        font-size: 30px;
        line-height: 40px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 20px;
        line-height: 26px;
    }
}

@media screen and (max-width:425px) {
    .valueationTitleContainer .title {
        font-size: 27px;
        line-height: 28px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 17px;
        line-height: 26px;
    }
}

@media screen and (max-width:375px) {
    .valueationTitleContainer {
        margin: 0;
        padding: 120px 0 50px;
    }

    .valueationTitleContainer .title {
        font-size: 24px;
        line-height: 26px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
    }
}

@media screen and (max-width:320px) {
    .valueationTitleContainer {
        margin: 0;
        padding: 120px 0 50px;
    }

    .valueationTitleContainer .title {
        font-size: 23px;
        line-height: 26px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -1px;
    }
}

.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
}

.vueTokenContainer {

}
.vueTokenTitle .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;
}
.vueTokenTitle .subTitle {
    display: block;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    white-space: pre-wrap;
}

.topImage {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
}
.topImage .topImageLeft img {
    width: 80%;
}

.bottomImage {
    text-align: center;
    margin-top: 100px;
}

.bottomImage img  {
    height: 662px;
    width: auto;
}

.vueTokenTitle.mobile {
    display: none;
}


@media screen and (max-width: 1550px){
    .vueTokenContainer {
        padding-left: 350px;
    }
}
@media screen and (max-width: 1450px){
    .vueTokenContainer {
        padding: 0 50px;
    }
    .vueTokenTitle .title {
        font-size: 26px;
        line-height: 32px;
    }
    .vueTokenTitle .subTitle {
        font-size: 18px;
        line-height: 26px;
    }
}

@media screen and (max-width:1250px) {
    /* 타블렛 가로 */
    .vueTokenContainer {
        padding: 0 50px;
    }
    .bottomImage img {
        height: 510px;
    }
}

@media screen and (max-width:1000px) {
    .vueTokenContainer {
        padding: 0 30px;
    }
    .vueTokenTitle .title {
        font-size: 30px;
        line-height: 40px;
    }
    .vueTokenTitle .subTitle {
        font-size: 19px;
        line-height: 32px;
        letter-spacing: -1px;
    }
    .bottomImage img {
        height: 420px;
        width: auto;
    }
}

@media screen and (max-width:768px) {
    .vueTokenTitle.desktop {
        display: none;
    }

    .vueTokenTitle.mobile {
        display: block;
    }

    .vueTokenTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vueTokenTitle.mobile .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
    }
    .vueTokenTitle.mobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }
    .vueTokenTitle span {
        padding: 0 30px;
    }

    .topImage {
        display: flex;
        flex-direction: column;
        margin: 0 30px;
    }
    .topImage .topImageLeft {
        margin: 30px 0 67px 0;
        text-align: center;
    }
    .topImage .topImageRight {
        margin-bottom: 39px;
    }
    .bottomImage {
        margin: 0 30px;
    }

    .bottomImage img {
        height: auto;
        width: 100%;
    }
    .vueTokenTitle.mobile .title {
        font-size: 28px;
        line-height: 48px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 20px;
        line-height: 32px;
    }
}
@media screen and (max-width:425px) {
    .vueTokenContainer {
        max-width: 425px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 23px;
        line-height: 31px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 16px;
        line-height: 26px;
    }
}
@media screen and (max-width:375px) {
    .vueTokenContainer {
        max-width: 375px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 21px;
        line-height: 29px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 15px;
        line-height: 24px;
    }
}
@media screen and (max-width:320px) {
    .vueTokenContainer {
        max-width: 320px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 19px;
        line-height: 26px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 14px;
        line-height: 22px;
    }
}

.vueExplanContents {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 150px;
}

.vueExplanContents .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 0px;
    text-align: center;
    margin-bottom: 50px;
}

.vueExplanContents .subTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 50px;
    white-space: pre-wrap;
}

.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
}

.valueContents {
    display: block;
    text-align: center;
    margin-bottom: 0;
}
/*blue value text*/
.valueContents .value {
    display: block;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #1A8DF7;
    margin-bottom: 50px;
    white-space: pre-wrap;
}
.valueContents .title{
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    white-space: pre-wrap;
}
.valueContents .subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    white-space: pre-wrap;
}
.valueContents .title.mobile{
    display: none;
    white-space: pre-wrap;
}
.valueContents .subTitle.mobile{
    display: none;
    white-space: pre-wrap;
}

.vueExplanImg {
    margin: 50px 0 0 0;
    width: 80%;
}
.vueValueImg {
    margin: 100px 100px 120px 150px;
    width: 80%;
}
.vueExplanText.mobile {
    display: none;
}

@media screen and (max-width: 1450px){
    .backgroundMargin {
        padding-top: 150px;
    }

    .vueExplanContents .title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .vueExplanContents .subTitle {
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueExplanImg {
        margin-top: 30px;
    }
    .vueExplanImg img {
        width: 50%;
    }


    .valueContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .valueContents .subTitle {
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueValueImg {
        margin: 50px 150px 150px 150px
    }
    .vueValueImg img {
        width: 50%;
    }


    /*blue value text*/
    .valueContents .value {
        font-size: 32px;
        line-height: 32px;
    }

}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
    .vueValueImg {
        margin: 50px auto 150px;
    }
}


@media screen and (max-width:1000px) {
    .vueExplanImg {
        margin: 0 auto;
        padding-top: 30px;
    }
    .vueExplanContents {
        padding-top: 100px;
    }
    .vueValueImg {
        margin: 30px auto 100px;
    }


    .vueExplanContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .vueExplanContents .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .valueContents .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 46px;
        letter-spacing: -1px;
    }
    .valueContents .subTitle {
        font-weight: 400;
        font-size: 19px;
        line-height: 28px;
        letter-spacing: -1px;
    }
    .vueExplanImg img {
        width: 80%;
    }
    .vueValueImg img {
        width: 80%;
    }

}
@media screen and (max-width:900px) {
    .vueExplanImg img {
        width: 70%;
    }
    .vueValueImg img {
        width: 70%;
    }
}


@media screen and (max-width:768px) {
    .backgroundMargin {
        padding-top: 30px;
        padding-bottom: 20px
    }

    .valueContents .vueValueImg {
        margin: 0 auto;
    }
    .vueExplanText {
        display: none;
    }

    .vueExplanText.mobile {
        display: block;
    }

    .vueExplanText {
        padding: 0 30px;
    }

    .vueExplanText .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        margin-bottom: 23px;
        white-space: pre-wrap;
    }
    .vueExplanText .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }
    .vueExplanContents {
        padding-top: 50px;
    }
    .vueExplanContents .vueExplanImg {
        padding: 0 16px;
    }

    .vueExplanImg {
        margin: 22px 0 0;
        width: 100%;
    }
    .vueValueImg {
        /*margin: 12px 0 30px;*/
        margin: 10px auto;
        width: 100%;
    }
    .vueValueImg img {
        margin: 25px 0;
    }


    .valueContents .value {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
    }
    .valueContents .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
    }
    .valueContents .subTitle {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        padding: 0 30px;
    }

    .valueContents .valueTextContents {
        padding: 0 30px;
    }

    .valueContents .vueValueImg {
        padding: 0 16px;
    }

    .valueContents .title.mobile{
        display: block;
    }
    .valueContents .subTitle.mobile{
        display: block;
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }

    .valueContents .title{
        display: none;
    }
    .valueContents .subTitle{
        display: none;
    }


    .valueContents .value {
        font-size: 36px;
        line-height: 45px;
    }

    .vueExplanText .title {
        font-size: 32px;
        line-height: 48px;
    }
    .vueExplanText .subTitle {
        font-size: 18px;
        line-height: 34px;
    }
    .valueContents .title {
        font-size: 32px;
        line-height: 48px;
    }
    .valueContents .subTitle.mobile {
        font-size: 18px;
        line-height: 34px;
    }
}
@media screen and (max-width:620px) {
    .vueExplanText .title {
        font-size: 30px;
        line-height: 48px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 34px;
    }
    .valueContents .title {
        font-size: 30px;
        line-height: 48px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 34px;
    }
}
@media screen and (max-width:560px) {

    .vueExplanText .title {
        font-size: 26px;
        line-height: 40px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 30px;
    }

    .valueContents .title {
        font-size: 26px;
        line-height: 40px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 30px;
    }
}

@media screen and (max-width:425px) {
    .vueExplanContents {
        max-width: 425px;
    }
    .valueContents {
        max-width: 425px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 15px 16px;
    }
    .vueExplanText .title {
        font-size: 28px;
        line-height: 32px;
    }
    .vueExplanText .subTitle {
        font-size: 17px;
        line-height: 29px;
    }
    .valueContents .title {
        font-size: 28px;
        line-height: 32px;
    }
    .valueContents .subTitle.mobile {
        font-size: 17px;
        line-height: 29px;
    }

    .valueContents .value {
        font-size: 25px;
    }
}

@media screen and (max-width:375px) {
    .vueExplanContents {
        max-width: 375px;
    }
    .valueContents {
        max-width: 375px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 18px 16px;
    }
    .vueExplanText .title {
        font-size: 23px;
        line-height: 30px;
    }
    .vueExplanText .subTitle {
        font-size: 15px;
        line-height: 27px;
    }
    .valueContents .title {
        font-size: 23px;
        line-height: 30px;
    }
    .valueContents .subTitle.mobile {
        font-size: 15px;
        line-height: 27px;
    }

    .valueContents .value {
        font-size: 23px;
    }
}

@media screen and (max-width:320px) {

    .vueExplanContents {
        max-width: 320px;
    }
    .valueContents {
        max-width: 320px;
    }
    .vueValueImg img {
        margin: 0 auto;
        padding: 20px 16px;
    }
    .vueExplanText .title {
        font-size: 19px;
        line-height: 0px;
    }
    .vueExplanText .subTitle {
        font-size: 14px;
        line-height: 22px;
    }
    .valueContents .title.mobile {
        font-size: 19px;
        line-height: 27px;
    }
    .valueContents .subTitle.mobile {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -1.5px;
    }

    .valueContents .value {
        font-size: 22px;
    }

}
.vueEarnText {
    text-align: center;
    margin-bottom: 100px;
    padding-top: 150px;
}

.vueEarnText .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    white-space: pre-wrap;
}
.vueEarnText .title.mobile{
    display: none;
}
.vueEarnText .subTitle {
    font-weight: 400;
    font-size: 23px;
    line-height: 40px;
    white-space: pre-wrap;
}
.vueEarnText .subTitle.mobile{
    display: none;
}

.vueEarnContents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    gap: 50px;
}
.vueEarnContents.mobile{
    display: none;
}

.vueEarnContents .vueEarnCommunityBox {
    /*padding: 30px;*/
    padding: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;
    position: relative;

}
.vueEarnContents .vueEarnCommunityBox .communityTextBox {
    height: 410px;
}
.vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: inherit;
    white-space: pre-wrap;
    margin-bottom: 15px;
    letter-spacing: 0px;
}
.vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
    /*width: 490px;*/
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    text-align: inherit;
    white-space: pre-wrap;
    letter-spacing: 0px;
}
.vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg {
    position: absolute;
    bottom: 45px;
    right: 50px;

}
.vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnFunBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnFunBox .funTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnFunBox .funTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnFunBox .funTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnFunBox .vueEarnFunImg {
    text-align: right;
}
.vueEarnContents .vueEarnFunBox .vueEarnFunImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnReportBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnReportBox .reportTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnReportBox .reportTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnReportBox .reportTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnReportBox .vueEarnReportImg {
    text-align: right;
}
.vueEarnContents .vueEarnReportBox .vueEarnReportImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnRewardBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnRewardBox .vueEarnRewardImg {
    text-align: right;
}
.vueEarnContents .vueEarnRewardBox .vueEarnRewardImg img {
    width: 70px;
    height: 70px;
}


/*@media screen and (min-width:769px) {*/
/*    .vueEarnMobile {*/
/*        !*display: none;*!*/
/*    }*/
/*    .vueEarnContentsMobile {*/
/*        display: none;*/
/*    }*/
/*}*/
@media screen and (max-width: 1800px) {
    .vueEarnContents {
        padding-left: 50px;
    }
}

@media screen and (max-width:1700px) {

}
@media screen and (max-width:1450px) {
    .vueEarnContainer {
        padding: 0 50px;
    }

    .vueEarnText .title {
        font-size: 26px;
        line-height: 32px;
    }
    .vueEarnText .sunTitle {
        font-size: 18px;
        line-height: 26px;
    }


    .vueEarnContents {
        padding-left: 0;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title{
        font-size: 24px;
        line-height: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        text-align: inherit;
        white-space: pre-wrap;
        letter-spacing: -1px;
    }
}
@media screen and (max-width:1250px) {
    .vueEarnContents {
        padding-left: 0;
    }
    .vueEarnContents .vueEarnCommunityBox {
        padding: 30px
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox{
        height: 420px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        text-align: inherit;
        white-space: pre-wrap;
        letter-spacing: -1px;
    }
    .vueEarnText .subTitle {
        font-size: 19px;
        line-height: 26px;
    }
}

@media screen and (max-width:1000px) {
    .vueEarnContents .vueEarnCommunityBox {
        /* padding: 30px; */
        padding: 25px;
    }
    .vueEarnText {
        padding-top: 100px;
    }
    .vueEarnContents {
        padding: 0 0 30px 0;
    }
    .vueEarnText .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnText .subTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -1px;
    }

    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 20px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 15px;
        letter-spacing: -1px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox {
        height: 495px;
    }
}

@media screen and (max-width:768px) {
    .vueEarnContainer {
        padding: 0 10px;
    }

    .vueEarnContents {
        margin: 0;
    }

    .vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg img {
        width: 50px;
        height: 50px;
    }
    .vueEarnContents.mobile{
        display: block;
        text-align: center;
        padding: 0 30px;
    }
    .vueEarnContents .vueEarnCommunityBox{
        padding: 30px;
        margin-bottom: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle{
        letter-spacing: 0;
    }
    .vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg{
        position: unset;
        bottom: unset;
        right: unset;
        text-align: center;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox{
        height: auto;

    }
    .vueEarnText {
        margin-bottom: 40px;
        padding: 50px 30px 0;
    }
    .vueEarnText .title.mobile{
        font-weight: 700;
        /*font-size: 24px;*/
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        display: block;
        margin-bottom: 23px;
    }
    .vueEarnText .subTitle.mobile{
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        display: block;
    }

    .vueEarnContentsMobile {
        margin: 0 16px;
    }
    .vueEarnMobile {
        background: #FFFFFF;
        box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
        border-radius: 26px;
        padding: 20px 20px;
        margin-bottom: 30px;
    }
    .vueEarnMobile .vueEarnImgMobile {
        text-align: center;
    }
    .vueEarnMobile .vueEarnImgMobile img {
        width: 50px;
        height: 50px;
    }
    .vueEarnMobile .vueEarnTextMobile .title {
        padding-top: 10px;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
    }
    .vueEarnMobile .vueEarnTextMobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
    }

    .vueEarnText .title.mobile {
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 22px;
        line-height: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 20px;
        line-height: 32px;
    }

    .vueEarnContents {
        display: none;
    }
    .vueEarnText .title {
        display: none;
    }
    .vueEarnText .subTitle {
        display: none;
    }
    .vueEarnText.mobile {
        white-space: pre-wrap;
    }
}

@media screen and (max-width:560px) {
    .vueEarnText .title.mobile {
        font-size: 24px;
        line-height: 40px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 20px;
        line-height: 32px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 18px;
    }
}

@media screen and (max-width:425px) {
    .vueEarnContainer {
        max-width: 420px;
        margin: 0 auto;
    }
    .vueEarnContents {
            margin: 0;
    }
    .vueEarnText .title.mobile {
        font-size: 20px;
        line-height: 32px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 18px;
        line-height: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 22px;
        line-height: 28px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 15px;
        line-height: 24px;
    }
    .vueValueImg img {
        width: 100%;
    }

}


@media screen and (max-width:410px) {
    .vueEarnContainer {
        max-width: 410px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 17px;
        line-height: 23px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 13.3px;
        line-height: 23px;
    }
    .vueValueImg img {
        width: 100%;
    }
}
@media screen and (max-width:375px) {
    .vueEarnContainer {
        max-width: 375px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnText .title.mobile {
        font-size: 20px;
        line-height: 27px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 16px;
        line-height: 22px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 18px;
        line-height: 28px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 12px;
        letter-spacing: -1px;
    }
    .vueValueImg img {
        width: 100%;
    }
}
@media screen and (max-width:320px) {
    .vueEarnContainer {
        max-width: 320px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnContents.mobile {
        padding: 0 20px;
        margin-bottom: 50px;
    }
    .vueEarnText .title.mobile {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -1px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 14px;
        line-height: 22px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 15px;
        line-height: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 11.8px;
        line-height: 18px;
        letter-spacing: -1px;
    }
    .vueValueImg img {
        width: 100%;
    }
}
.AdminLayout{
    background-color: #222831;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.AdminWrap{
    max-width: 1420px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.wrap.banner{*/
/*    max-width: 100%;*/
/*    height: 100%;*/
/*}*/

.SignInLayout {
    background-color: #222831;
    width: 100%;
    height: 100vh;
}
.signInContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: black;
}

.signInContainer .formContainer {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(255, 255, 255, 0.8);
    border-radius: 26px;
    text-align: center;
    overflow: hidden;
}

.signInContainer .formContainer input {
    display: block;
    text-align: left;
    width: 15vw;
    height: 50px;
    padding-left: 10px;
}

.inputContainer {
    padding: 15px 50px 15px 50px;
}

.signIn-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    padding-top: 15px;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%)
}

.signIn-header .signIn-title {
    padding-bottom: 5px;
    display: block;
    font-weight: bold;
    font-size: 30px;

}

.formContainer .label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.buttonContainer {
    width: 80%;
    padding: 10px;
    margin: 0 auto;
}

.buttonContainer button {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0px 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    border-radius: 26px;
    border: none;
    font-size: 26px;
    color: white;
    padding: 8px;
}

.errorMsg {
    display: block;
    text-align: left;
    padding-left: 50px;
    color: red;
    font-size: 12px;
}

.signIn-side-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #E5F8FF;
    align-items: center;
    justify-content: center;
}

.signIn-side-img-container {
    width: 100%;
    padding-bottom: 115px;
    text-align: center;
}

.signIn-info {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 28px;
    /*font-weight: bold;*/
    line-height: 50px;
    color: #08132A;
}
.AdminMainContainer {
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 20px;
}

.AdminTableContainer {
    width: 100%;
}
 .AdminTableContainer table {
    width: 100%;
}

.AdminTableContainer th, .AdminTableContainer td {
    padding: 15px 10px;
    box-sizing: border-box;
}

.AdminTableContainer tr .checkContact {
    color: #3755F1;
}

.AdminTableContainer tr .noneContact {
    color: red;
}

.AdminTableContainer .id {
    width: 80px
}

.AdminTableContainer .checkIcon svg path {
    color: #008000;
}

.Scroll-wrap {
    width: 100%;
    overflow: auto;
    max-height: 280px;
    padding-bottom: 20px;
    background: #FBF3E4;
    box-shadow: rgba(150, 100, 125, 0.2) 3px 3px 10px inset;
}

.Scroll-wrap .Contact-content {
    font-weight: bold;
    font-size: large;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 35px;
    padding-top: 28px;
    word-break: break-all;

}

.Button-wrap {
    width: 100%;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Table-Header-Sort {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
}

.Table-Header-Sort .Table-Header-Icon {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.triangle-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: left;
    margin-top: 2px;
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    float: left;
}

.triangle-down-gray {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #D5D5D5;
    font-size: 0;
    line-height: 0;
    float: left;
    margin-top: 2px;
}

.triangle-up-gray {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid #D5D5D5;
    font-size: 0;
    line-height: 0;
    float: left;
}



/* 영문 */
/*@import url('Illegal.css');*/



/* admin */




*{margin: 0; padding:0; font-family: 'Montserrat','Noto Sans KR' , sans-serif; color:#333;}
input{appearance: none; -moz-appearance: none; /* Firefox */ -webkit-appearance: none; /* Safari and Chrome */ border-radius: 0;}
textarea{appearance: none; -moz-appearance: none; /* Firefox */ -webkit-appearance: none; /* Safari and Chrome */ border-radius: 0;}

