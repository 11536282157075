.mainBannerContainer {
    width: 100%;
    height: 825px;
    background-image: url("../assets/images/MainBannerBG.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160px;
    padding-left: 300px;
    box-sizing: border-box;
    margin-bottom: 85px;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.mainVideoBannerContainer {
    width: 100%;
    height: 825px;
    margin-bottom: 85px;
    position: relative;
}

.mainVideoBannerContainer .bgContent {
    position: absolute;
    top: 160px;
    right: 300px;
}

/*.mainVideoBannerContainer .bgContent{*/
/*    padding-top: 160px;*/
/*    padding-left:300px;*/
/*    box-sizing: border-box;*/
/*}*/
.mainVideoBannerContainer video {
    min-width: 100%;
    min-height: 100%;

}


.mainVideoBannerContainer .title, .mainBannerContainer .title {
    font-weight: bold;
    font-size: 54px;
    line-height: 81px;
    text-align: center;
    color: #fff;
    display: block;
    margin-bottom: 21px;
    white-space: pre-wrap;

}

.mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    display: block;
    color: #fff;
    margin-bottom: 56px;
    white-space: pre-wrap;

}

.mainVideoBannerContainer .createButton, .mainBannerContainer .createButton {
    display: block;
    background-color: inherit;
    background-image: url("../assets/images/CreateNFTbutton.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 240px;
    height: 51px;
    border: none;
    margin: 0 auto;
    cursor: pointer;
}

.title.mobile {
    display: none;
}
.title {
    white-space: pre-wrap;
}


@media screen and (max-width: 1550px) {
}

@media screen and (max-width: 1450px) {
    /* 타블렛 가로 */
    .mainBannerContainer {
        padding-left: 0;
        /*background-image: url("assets/images/MainBannerBG_mobile.png");*/
        /*background-position: center center;*/
    }
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 45px;
        line-height: 60px;
        padding: 0 30px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 24px;
        font-weight: 400;
        /*margin-bottom: 30px;*/
    }
}

/*@media screen and (max-width: 860px) {*/
@media screen and (max-width: 1000px) {
    .mainBannerContainer {
        background-position: center center;
        margin-bottom: 0;
    }
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 40px;
        line-height: 60px;
        padding: 0 30px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 24px;
        font-weight: 400;
        /*margin-bottom: 30px;*/
    }
}

@media screen and (max-width: 768px) {
    /* 모바일 가로, 타블렛 세로 */
    .mainBannerContainer {
        padding-top: 120px;
        height: 615px;
        margin-bottom: 0;
        background-position: center;
        /*background-size: contain;*/
        background-image: url("assets/images/MainBannerBG_mobile.png");
    }

    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 40px;
        line-height: 48px;
    }

    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 26px;
        font-weight: 400;
        margin-bottom: 30px;
    }

    .title.desktop {
        display: none;
    }

    .title.mobile {
        display: block;
    }
}
@media screen and (max-width: 560px) {
    .mainBannerContainer .title {
        font-size: 28px;
        line-height: 40px;
    }
    .mainBannerContainer .subTitle {
        font-size: 19px;
        line-height: 30px;
    }
}

@media screen and (max-width: 425px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 26px;
        line-height: 35px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 375px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 24px;
        line-height: 33px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 16px;
    }
}

@media screen and (max-width: 320px) {
    .mainVideoBannerContainer .title, .mainBannerContainer .title {
        font-size: 20px;
        line-height: 30px;
    }
    .mainVideoBannerContainer .subTitle, .mainBannerContainer .subTitle {
        font-size: 13px;
    }
}
