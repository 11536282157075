.valueationTitleContainer {
    margin-top: 130px;
    margin-bottom: 60px;
}

.valueationTitleContainer .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
}

.valueationTitleContainer .subTitle {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}

@media screen and (max-width: 1450px){
    .valueationTitleContainer .subTitle {
        letter-spacing: -1px;
    }
}

@media screen and (max-width:1200px) {
    /* 타블렛 가로 */
}
@media screen and (max-width:1000px) {
    .valueationTitleContainer {
        margin-top: 200px;
        margin-bottom: 0px;
    }

    .valueationTitleContainer .title {
        font-size: 30px;
        line-height: 40px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 18.5px;
        line-height: 32px;
        letter-spacing: -1px;
    }
}

@media screen and (max-width:768px) {
    .valueationTitleContainer {
        margin-top: 120px;
        margin-bottom: 50px;
    }


    .valueationTitleContainer .title {
        font-weight: 700;
        font-size: 34px;
        line-height: 48px;
        text-align: center;
        color: #1A8DF7;
    }
    .valueationTitleContainer .subTitle {
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        text-align: center;
    }
    .valueationTitleContainer span {
        padding: 0 30px;
    }
}

@media screen and (max-width:560px) {
    .valueationTitleContainer .title {
        font-size: 30px;
        line-height: 40px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 20px;
        line-height: 26px;
    }
}

@media screen and (max-width:425px) {
    .valueationTitleContainer .title {
        font-size: 27px;
        line-height: 28px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 17px;
        line-height: 26px;
    }
}

@media screen and (max-width:375px) {
    .valueationTitleContainer {
        margin: 0;
        padding: 120px 0 50px;
    }

    .valueationTitleContainer .title {
        font-size: 24px;
        line-height: 26px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
    }
}

@media screen and (max-width:320px) {
    .valueationTitleContainer {
        margin: 0;
        padding: 120px 0 50px;
    }

    .valueationTitleContainer .title {
        font-size: 23px;
        line-height: 26px;
    }
    .valueationTitleContainer .subTitle {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -1px;
    }
}
