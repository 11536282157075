.backgroundMargin {
    background: rgba(26, 141, 247, 0.04);
}

.vueTokenContainer {

}
.vueTokenTitle .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    white-space: pre-wrap;
}
.vueTokenTitle .subTitle {
    display: block;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    white-space: pre-wrap;
}

.topImage {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 100px;
}
.topImage .topImageLeft img {
    width: 80%;
}

.bottomImage {
    text-align: center;
    margin-top: 100px;
}

.bottomImage img  {
    height: 662px;
    width: auto;
}

.vueTokenTitle.mobile {
    display: none;
}


@media screen and (max-width: 1550px){
    .vueTokenContainer {
        padding-left: 350px;
    }
}
@media screen and (max-width: 1450px){
    .vueTokenContainer {
        padding: 0 50px;
    }
    .vueTokenTitle .title {
        font-size: 26px;
        line-height: 32px;
    }
    .vueTokenTitle .subTitle {
        font-size: 18px;
        line-height: 26px;
    }
}

@media screen and (max-width:1250px) {
    /* 타블렛 가로 */
    .vueTokenContainer {
        padding: 0 50px;
    }
    .bottomImage img {
        height: 510px;
    }
}

@media screen and (max-width:1000px) {
    .vueTokenContainer {
        padding: 0 30px;
    }
    .vueTokenTitle .title {
        font-size: 30px;
        line-height: 40px;
    }
    .vueTokenTitle .subTitle {
        font-size: 19px;
        line-height: 32px;
        letter-spacing: -1px;
    }
    .bottomImage img {
        height: 420px;
        width: auto;
    }
}

@media screen and (max-width:768px) {
    .vueTokenTitle.desktop {
        display: none;
    }

    .vueTokenTitle.mobile {
        display: block;
    }

    .vueTokenTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vueTokenTitle.mobile .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
    }
    .vueTokenTitle.mobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        white-space: pre-wrap;
    }
    .vueTokenTitle span {
        padding: 0 30px;
    }

    .topImage {
        display: flex;
        flex-direction: column;
        margin: 0 30px;
    }
    .topImage .topImageLeft {
        margin: 30px 0 67px 0;
        text-align: center;
    }
    .topImage .topImageRight {
        margin-bottom: 39px;
    }
    .bottomImage {
        margin: 0 30px;
    }

    .bottomImage img {
        height: auto;
        width: 100%;
    }
    .vueTokenTitle.mobile .title {
        font-size: 28px;
        line-height: 48px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 20px;
        line-height: 32px;
    }
}
@media screen and (max-width:425px) {
    .vueTokenContainer {
        max-width: 425px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 23px;
        line-height: 31px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 16px;
        line-height: 26px;
    }
}
@media screen and (max-width:375px) {
    .vueTokenContainer {
        max-width: 375px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 21px;
        line-height: 29px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 15px;
        line-height: 24px;
    }
}
@media screen and (max-width:320px) {
    .vueTokenContainer {
        max-width: 320px;
    }
    .vueTokenTitle.mobile .title {
        font-size: 19px;
        line-height: 26px;
    }
    .vueTokenTitle.mobile .subTitle {
        font-size: 14px;
        line-height: 22px;
    }
}
