.vueEarnText {
    text-align: center;
    margin-bottom: 100px;
    padding-top: 150px;
}

.vueEarnText .title {
    font-weight: 700;
    font-size: 38px;
    line-height: 50px;
    white-space: pre-wrap;
}
.vueEarnText .title.mobile{
    display: none;
}
.vueEarnText .subTitle {
    font-weight: 400;
    font-size: 23px;
    line-height: 40px;
    white-space: pre-wrap;
}
.vueEarnText .subTitle.mobile{
    display: none;
}

.vueEarnContents {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}
.vueEarnContents.mobile{
    display: none;
}

.vueEarnContents .vueEarnCommunityBox {
    /*padding: 30px;*/
    padding: 40px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;
    position: relative;

}
.vueEarnContents .vueEarnCommunityBox .communityTextBox {
    height: 410px;
}
.vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: inherit;
    white-space: pre-wrap;
    margin-bottom: 15px;
    letter-spacing: 0px;
}
.vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
    /*width: 490px;*/
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    text-align: inherit;
    white-space: pre-wrap;
    letter-spacing: 0px;
}
.vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg {
    position: absolute;
    bottom: 45px;
    right: 50px;

}
.vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnFunBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnFunBox .funTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnFunBox .funTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnFunBox .funTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnFunBox .vueEarnFunImg {
    text-align: right;
}
.vueEarnContents .vueEarnFunBox .vueEarnFunImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnReportBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnReportBox .reportTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnReportBox .reportTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnReportBox .reportTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnReportBox .vueEarnReportImg {
    text-align: right;
}
.vueEarnContents .vueEarnReportBox .vueEarnReportImg img {
    width: 70px;
    height: 70px;
}

.vueEarnContents .vueEarnRewardBox {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
    border-radius: 26px;;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox {
    height: 320px;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: inherit;
}
.vueEarnContents .vueEarnRewardBox .rewardTextBox .subTitle {
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: inherit;
    margin: 0
}
.vueEarnContents .vueEarnRewardBox .vueEarnRewardImg {
    text-align: right;
}
.vueEarnContents .vueEarnRewardBox .vueEarnRewardImg img {
    width: 70px;
    height: 70px;
}


/*@media screen and (min-width:769px) {*/
/*    .vueEarnMobile {*/
/*        !*display: none;*!*/
/*    }*/
/*    .vueEarnContentsMobile {*/
/*        display: none;*/
/*    }*/
/*}*/
@media screen and (max-width: 1800px) {
    .vueEarnContents {
        padding-left: 50px;
    }
}

@media screen and (max-width:1700px) {

}
@media screen and (max-width:1450px) {
    .vueEarnContainer {
        padding: 0 50px;
    }

    .vueEarnText .title {
        font-size: 26px;
        line-height: 32px;
    }
    .vueEarnText .sunTitle {
        font-size: 18px;
        line-height: 26px;
    }


    .vueEarnContents {
        padding-left: 0;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title{
        font-size: 24px;
        line-height: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        text-align: inherit;
        white-space: pre-wrap;
        letter-spacing: -1px;
    }
}
@media screen and (max-width:1250px) {
    .vueEarnContents {
        padding-left: 0;
    }
    .vueEarnContents .vueEarnCommunityBox {
        padding: 30px
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox{
        height: 420px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        text-align: inherit;
        white-space: pre-wrap;
        letter-spacing: -1px;
    }
    .vueEarnText .subTitle {
        font-size: 19px;
        line-height: 26px;
    }
}

@media screen and (max-width:1000px) {
    .vueEarnContents .vueEarnCommunityBox {
        /* padding: 30px; */
        padding: 25px;
    }
    .vueEarnText {
        padding-top: 100px;
    }
    .vueEarnContents {
        padding: 0 0 30px 0;
    }
    .vueEarnText .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnText .subTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -1px;
    }

    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 20px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 15px;
        letter-spacing: -1px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox {
        height: 495px;
    }
}

@media screen and (max-width:768px) {
    .vueEarnContainer {
        padding: 0 10px;
    }

    .vueEarnContents {
        margin: 0;
    }

    .vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg img {
        width: 50px;
        height: 50px;
    }
    .vueEarnContents.mobile{
        display: block;
        text-align: center;
        padding: 0 30px;
    }
    .vueEarnContents .vueEarnCommunityBox{
        padding: 30px;
        margin-bottom: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle{
        letter-spacing: 0;
    }
    .vueEarnContents .vueEarnCommunityBox .vueEarnCommunityImg{
        position: unset;
        bottom: unset;
        right: unset;
        text-align: center;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox{
        height: auto;

    }
    .vueEarnText {
        margin-bottom: 40px;
        padding: 50px 30px 0;
    }
    .vueEarnText .title.mobile{
        font-weight: 700;
        /*font-size: 24px;*/
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        display: block;
        margin-bottom: 23px;
    }
    .vueEarnText .subTitle.mobile{
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        display: block;
    }

    .vueEarnContentsMobile {
        margin: 0 16px;
    }
    .vueEarnMobile {
        background: #FFFFFF;
        box-shadow: 0px 8px 30px rgba(26, 141, 247, 0.16);
        border-radius: 26px;
        padding: 20px 20px;
        margin-bottom: 30px;
    }
    .vueEarnMobile .vueEarnImgMobile {
        text-align: center;
    }
    .vueEarnMobile .vueEarnImgMobile img {
        width: 50px;
        height: 50px;
    }
    .vueEarnMobile .vueEarnTextMobile .title {
        padding-top: 10px;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
    }
    .vueEarnMobile .vueEarnTextMobile .subTitle {
        display: block;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
    }

    .vueEarnText .title.mobile {
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 22px;
        line-height: 30px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 30px;
        line-height: 40px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 20px;
        line-height: 32px;
    }

    .vueEarnContents {
        display: none;
    }
    .vueEarnText .title {
        display: none;
    }
    .vueEarnText .subTitle {
        display: none;
    }
    .vueEarnText.mobile {
        white-space: pre-wrap;
    }
}

@media screen and (max-width:560px) {
    .vueEarnText .title.mobile {
        font-size: 24px;
        line-height: 40px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 20px;
        line-height: 32px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 18px;
    }
}

@media screen and (max-width:425px) {
    .vueEarnContainer {
        max-width: 420px;
        margin: 0 auto;
    }
    .vueEarnContents {
            margin: 0;
    }
    .vueEarnText .title.mobile {
        font-size: 20px;
        line-height: 32px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 18px;
        line-height: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 22px;
        line-height: 28px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 15px;
        line-height: 24px;
    }
    .vueValueImg img {
        width: 100%;
    }

}


@media screen and (max-width:410px) {
    .vueEarnContainer {
        max-width: 410px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 17px;
        line-height: 23px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 13.3px;
        line-height: 23px;
    }
    .vueValueImg img {
        width: 100%;
    }
}
@media screen and (max-width:375px) {
    .vueEarnContainer {
        max-width: 375px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnText .title.mobile {
        font-size: 20px;
        line-height: 27px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 16px;
        line-height: 22px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 18px;
        line-height: 28px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 12px;
        letter-spacing: -1px;
    }
    .vueValueImg img {
        width: 100%;
    }
}
@media screen and (max-width:320px) {
    .vueEarnContainer {
        max-width: 320px;
    }
    .vueEarnContents {
        margin: 0;
    }
    .vueEarnContents.mobile {
        padding: 0 20px;
        margin-bottom: 50px;
    }
    .vueEarnText .title.mobile {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -1px;
    }
    .vueEarnText .subTitle.mobile {
        font-size: 14px;
        line-height: 22px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .title {
        font-size: 15px;
        line-height: 24px;
    }
    .vueEarnContents .vueEarnCommunityBox .communityTextBox .subTitle {
        font-size: 11.8px;
        line-height: 18px;
        letter-spacing: -1px;
    }
    .vueValueImg img {
        width: 100%;
    }
}