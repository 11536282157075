.signInContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: black;
}

.signInContainer .formContainer {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(255, 255, 255, 0.8);
    border-radius: 26px;
    text-align: center;
    overflow: hidden;
}

.signInContainer .formContainer input {
    display: block;
    text-align: left;
    width: 15vw;
    height: 50px;
    padding-left: 10px;
}

.inputContainer {
    padding: 15px 50px 15px 50px;
}

.signIn-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    padding-top: 15px;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%)
}

.signIn-header .signIn-title {
    padding-bottom: 5px;
    display: block;
    font-weight: bold;
    font-size: 30px;

}

.formContainer .label {
    display: block;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.buttonContainer {
    width: 80%;
    padding: 10px;
    margin: 0 auto;
}

.buttonContainer button {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #00C2E7 0%, #6300F9 100%);
    box-shadow: 0px 5px 12px rgba(55, 85, 241, 0.2), inset 0px 5px 5px rgba(255, 255, 255, 0.3);
    border-radius: 26px;
    border: none;
    font-size: 26px;
    color: white;
    padding: 8px;
}

.errorMsg {
    display: block;
    text-align: left;
    padding-left: 50px;
    color: red;
    font-size: 12px;
}

.signIn-side-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #E5F8FF;
    align-items: center;
    justify-content: center;
}

.signIn-side-img-container {
    width: 100%;
    padding-bottom: 115px;
    text-align: center;
}

.signIn-info {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 28px;
    /*font-weight: bold;*/
    line-height: 50px;
    color: #08132A;
}